import axios from '@services/api/axios';

const api = {
  searchDocuments: (page, data) =>
    axios.post(`document/search?page=${page}`, data),
  getDocument: (documentID: string) => axios.get(`document/${documentID}`),
  getSettings: (data) => axios.post('document-constructor/all/setting', data),
  addDocument: (data) => axios.post('document', data),
  addTabular: (data) => axios.post('row-column', data),
  addAttribute: (data) => axios.post('attribute', data),
  getColumnByDocId: (data) => axios.post(`rowColumnByDocId `, data),
  removeTabular: (id) => axios.delete(`document-row/${id}`),
  getDocumentCoordination: (data: { document_id: number , document_coordination: any}) => axios.post('document-coordination', data),
  saveEditedCoordination: (data: { document_id: number , document_coordination: any}) => axios.post('document-coordination/signatoryEdit', data),
  sendList: (data) => axios.post('coordination/send/agreement', data),
  deleteDocument: (documentID) => axios.delete(`document/${documentID}`),
  getForm: (data: {
    document_id: number | undefined;
    document_type?: string;
  }) => axios.post('/document-note-file', data, { responseType: 'blob' }),
  searchInn: (data: { iin: number }) => axios.post('search/iin', data),
  getContractor: (data: { iin: number }) => axios.post("client/iin", data),
  getAdminDays: (data) => axios.post('userAdminDays' , data),
  getCountVacationDays: (data) => axios.post('getCountVacationDays', data),
  deleteFile: (data) => axios.post('document/delete_file' , data),
  testUpdateOneRowTable: (data) => axios.put(`/row-column/${data.id}` , data),
  getDocClassCoordination: (id) => axios.get( `docClassCoordination/getByDocId/${id}`),
  putDocClassCoordination: (id, data) => axios.put(`docClassCoordination/${id}`, data),
  deleteDocClassCoordination: (id) => axios.delete(`docClassCoordination/${id}`),
  createSecuritiesDocuments: (data) => axios.post('document/securities/create', data),
  getColumnEdo: (data) => axios.post('/handbook/getByColumn', data),
  createOrderDoc: (data) => axios.post('document/securities/order/create', data),
  getDocumentInventory: (iin) => axios.get(`/document/inventory/${iin}`),
};

export default api;
