import { Table } from "antd";
import { AgreementFull } from "../type";

interface IProps {
    rawData: AgreementFull | null,
}

const columns = [
    {
      title: "Фио",
      dataIndex: ["number_value", "full_name"],
      key: "name",
    },
    {
      title: "Роль",
      dataIndex: ["attribute_type", "full_name"],
      key: "role",
    },
];

export default function InventData({ rawData }: IProps) {
    return (
        <Table 
            dataSource={rawData?.attributes} 
            columns={columns} 
            rowKey="id"
            pagination={false}
            style={{marginBottom: '20px'}}
        />
    );
}
