import DepartmentUser from '@modules/common/Edo/Field/DepartmentUser';
import DutyUser from '@modules/common/Edo/Field/DutyUser';
import FieldUser from '@modules/common/Edo/Field/FieldUser';
import FieldUserCard from '@modules/common/Edo/Field/FieldUserCard';
import TreeSelectHandbook from '@modules/TreeSelect/TreeSelectHandook';
import AdminDay from '@modules/common/Edo/Field/AdminDay';
import DayCount from '@modules/common/Edo/Field/DayCount';
import AgreementAhd from '@modules/common/Edo/Field/AgreementAhd';
import 'dayjs/locale/ru';
import {Button, Checkbox, Col, DatePicker, Form, Input, InputNumber, message, Select, Space,} from 'antd';
import { ColleaguesListType } from '@hooks/useColleagues';
import { HandbookListType } from '@hooks/useHandbook';
import { IRowColumnEditor } from '../types';
import SQLField from '@modules/common/Edo/Field/SQLField';
import { useEffect, useState } from 'react';
import SelectAdminDay from '@modules/common/Edo/Field/SelectAdminDay';
import DependentDatePicker from '@modules/common/Edo/Field/DependentDatePicker';
import AgreementField from '@modules/common/Edo/Field/AgreementField';
import FieldContractorCard from '@modules/common/Edo/Field/FieldContractorCard';
import TreeSelectCollegues from '@modules/TreeSelect/TreeSelectCollegues';
import dayjs from 'dayjs';
import SelectCustomTag from '@modules/Edo/SelectCustomTag/SelectCustomTag';
import api from "@services/api";
import {EditOutlined} from "@ant-design/icons";

const { Option } = Select;

interface IInventoryItem {
	inventory_number: string;
	os_name: string;
}

interface Props {
	settings: IRowColumnEditor;
	name: number;
	colleaguesList: ColleaguesListType;
	departmentList: ColleaguesListType;
	handbookList: HandbookListType;
	isDisabledForm: boolean;
	isDouble?: boolean;
	attrForm: any;
	id: number;
	isVacation?: boolean;
	docData?: object | any;
	onChangeByTd: any;
	isAdmin: any;
	isDisabledStatus: boolean;
	isDocEditCs: boolean;
	itemInventory?: IInventoryItem;
	inventoryList: IInventoryItem[];
}

function EdoRowColumnField(props: Props) {
	const {
		settings,
		name,
		colleaguesList = [],
		departmentList = [],
		handbookList = [],
		isDisabledForm = false,
		isDouble = false,
		attrForm,
		id,
		isVacation = false,
		docData,
		onChangeByTd,
		isAdmin,
		isDisabledStatus,
		isDocEditCs,
		inventoryList,
	} = props;

	const form = Form.useFormInstance();
	const propertyValue = Form.useWatch(['row_column_editor', name], form);

	const onChange = (id, name) => {
		if (isAdmin && isDisabledStatus) {
			onChangeByTd(id, name);
		}
	};

	console.log(docData?.class?.constant === 'OrderReport')
	console.log(isDocEditCs)

	switch (settings.type) {
		case 'SUBJECT':
		case 'SUBJECT_SHORT':
			// if (settings.show_column) {
				return (
					<Col span={8} xs={24} xl={8}>
						<FieldUserCard
							name={name}
							title={settings.field_name}
							fieldID={`id-${settings.id}`}
							fieldName="char_value"
							required={settings.required}
							constant={settings.constant}
							id={id}
							onChangeByTd={onChangeByTd}
							isAdmin={isAdmin}
							isDisabledStatus={isDisabledStatus}
						/>
					</Col>
				);
			// }
			// return <></>;
		case 'SUBJECT_CONTRACTOR':
			if (settings.show_column) {
				return (
					<Col span={8} xs={24} xl={8}>
						<FieldContractorCard
							name={name}
							title={settings.field_name}
							fieldID={`id-${settings.id}`}
							fieldName="char_value"
							isDisabledForm={isDisabledForm}
							required={settings.required}
							constant={settings.constant}
							id={id}
							onChangeByTd={onChangeByTd}
							isAdmin={isAdmin}
							isDisabledStatus={isDisabledStatus}
						/>
					</Col>
				);
			}
			return <></>;
		case 'EMPL':
		case 'DEPTEMPL':
			if (settings.field_name.toLowerCase() === 'фио работника') {
				if (settings.show_column) {
					return (
						<Col span={8} xs={24} xl={8}>
							<FieldUser
								colleaguesList={colleaguesList}
								property="num_value"
								name={name}
								settings={settings}
								isDisabledForm={isDisabledForm}
								isDouble={isDouble}
								isVacation={isVacation}
								attrForm={attrForm}
								id={id}
								onChangeByTd={onChangeByTd}
								isAdmin={isAdmin}
								isDisabledStatus={isDisabledStatus}
							/>
						</Col>
					);
				}
			} else {
				if (settings.show_column) {
					return (
						<Col span={8} xs={24} xl={8}>
							<FieldUser
								colleaguesList={colleaguesList}
								property="num_value"
								name={name}
								settings={settings}
								isDisabledForm={isDisabledForm}
								attrForm={attrForm}
								id={id}
								onChangeByTd={onChangeByTd}
								isAdmin={isAdmin}
								isDisabledStatus={isDisabledStatus}
							/>
						</Col>
					);
				}
			}
			return <></>;
		case 'DEPT':
			if (settings.show_column) {
				return (
					<Col span={8} xs={24} xl={8}>
						<DepartmentUser
							settings={settings}
							departmentList={departmentList}
							name={name}
							isDisabledForm={isDisabledForm}
							onChangeByTd={onChangeByTd}
							isAdmin={isAdmin}
							isDisabledStatus={isDisabledStatus}
						/>
					</Col>
				);
			}
			return <></>;
		case 'DUTY':
			if (settings.show_column) {
				return (
					<Col span={8} xs={24} xl={8}>
						<DutyUser
							settings={settings}
							handbookList={handbookList}
							name={name}
							isDisabledForm={isDisabledForm}
							onChangeByTd={onChangeByTd}
							isAdmin={isAdmin}
							isDisabledStatus={isDisabledStatus}
						/>
					</Col>
				);
			}
			return <></>;
		case 'SQL':
			if (settings.show_column) {
				return (
					<Col span={8} xs={24} xl={8}>
						<SQLField
							settings={settings}
							handbookList={handbookList}
							name={name}
							onChangeByTd={onChangeByTd}
							isAdmin={isAdmin}
							isDisabledStatus={isDisabledStatus}
						/>
					</Col>
				);
			}
			return <></>;
		case 'DOCS':
			if (settings.show_column) {
				return (
					<Col span={8} xs={24} xl={8}>
						{
							<AgreementAhd
								name={name}
								title={settings.field_name}
								fieldID={`id-${settings.id}`}
								fieldName="char_value"
								required={settings.required}
								id={id}
								isDisabledForm={
									isDisabledForm ? isDisabledForm : settings.readonly
								}
								onChangeByTd={onChangeByTd}
								isAdmin={isAdmin}
								isDisabledStatus={isDisabledStatus}
							/>
						}
					</Col>
				);
			}
			return <></>;
		case 'DICTI':
			if (
				settings.field_name.toLowerCase() == 'указать кол-во используемых дней'
			) {
				return (
					<Col
						span={8}
						xs={24}
						xl={8}
						className={`name: ${name.toString()} id: ${settings.id}`}
					>
						<SelectAdminDay
							settings={settings}
							name={name}
							handbookList={handbookList}
							isDisabledForm={isDisabledForm}
							onChangeByTd={onChangeByTd}
							isAdmin={isAdmin}
							isDisabledStatus={isDisabledStatus}
						/>
					</Col>
				);
			} else if (settings.field_name.toLowerCase() == 'цель заключения сделки') {
				return (
					<Col span={8} xs={24} xl={8}>
						<SelectCustomTag
							name={name}
							settings={settings}
							handbookList={handbookList}
							form={form}
						/>
					</Col>
				)
			} else if (settings.const == 'order_status') {
				return (
					<Col span={8} xs={24} xl={8}>
						<Form.Item
							label={settings.field_name}
							name={[name, `id-${settings.id}`, 'num_value']}
							rules={[{ required: settings.required }]}
							hidden={!['signed_ecp', 'success', 'partially_completed', 'not_fulfilled'].includes(docData?.stage?.slug)}
						>
							<TreeSelectHandbook
								onChange={() => {
									if (docData.doc_row.data[0].some(row => row.editor_id === settings.id)) {
										onChangeByTd(settings.id, name);
									} else {
										const data = {
											display_no: settings.display_no,
											document_id: docData.id,
											editor_id: settings.id,
											full_name: settings.full_name,
											type: settings.type,
											char_value: form.getFieldsValue().row_column_editor[0][`id-${settings.id}`].char_value,
											document_row_id: docData.doc_row.data[0]['0'].document_row_id,
										};

										api.edo.addTabular([[data]]);
									}

								}}
								settings={settings}
								handbookList={handbookList}
								disabled={(docData?.class?.constant === 'OrderReport'
									? !isDocEditCs
									: isDisabledForm || settings.readonly)}
							/>
						</Form.Item>
					</Col>
				)
			} else {
				return (
					<Col span={8} xs={24} xl={8}>
						<Form.Item
							label={settings.field_name}
							name={[name, `id-${settings.id}`, 'num_value']}
							rules={[{ required: settings.required }]}
							hidden={!settings.show_column}
						>
							<TreeSelectHandbook
								onChange={() => onChange(settings.id, name)}
								settings={settings}
								handbookList={handbookList}
								disabled={isDisabledForm ? isDisabledForm : settings.readonly}
							/>
						</Form.Item>
					</Col>
				);
			}
		case 'CHECKBOX':
			if (settings.show_column) {
				return (
					<Col span={8} xs={24} xl={8}>
						<Form.Item
							label={settings.field_name}
							name={[name, `id-${settings.id}`, 'num_value']}
							valuePropName="checked"
							rules={[{ required: settings.required }]}
							hidden={!settings.show_column}
						>
							<Checkbox
								onChange={() => onChange(settings.id, name)}
								disabled={isDisabledForm}
							>
								{settings.field_name}
							</Checkbox>
						</Form.Item>
					</Col>
				);
			};
			return <></>;
		case 'INTEGER':
		case 'NUMBER':
			if (settings.constant == 'cAddAttrForCar') {
				let type = true;
				for (let key in propertyValue) {
					if (
						propertyValue[key].full_name == 'Вид транспорта' &&
						propertyValue[key].num_value == 963
					) {
						type = false;
					}
				}
				return (
					<Col span={8} xs={24} xl={8}>
						<Form.Item
							label={settings.field_name}
							name={[name, `id-${settings.id}`, 'num_value']}
							rules={[{ required: settings.required }]}
							hidden={type}
							noStyle={type}
						>
							<InputNumber
								onChange={() => onChange(settings.id, name)}
								formatter={(value) =>
									`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
								}
								parser={(x) => parseFloat(`${x}`.replace(/\s/g, ''))}
								style={{ width: '100%' }}
								disabled={isDisabledForm ? isDisabledForm : settings.readonly}
							/>
						</Form.Item>
					</Col>
				);
			} else if (settings.field_name.toLowerCase() == 'итого кол-во дней') {
				return (
					<Col span={8} xs={24} xl={8}>
						<DayCount
							onChangeByTd={onChangeByTd}
							isAdmin={isAdmin}
							isDisabledStatus={isDisabledStatus}
							settings={settings}
							name={name}
							docData={docData}
						/>
					</Col>
				);
			} else if (settings.field_name.toLowerCase() == 'доступное кол-во дней') {
				return (
					<Col span={8} xs={24} xl={8}>
						<AdminDay
							onChangeByTd={onChangeByTd}
							isAdmin={isAdmin}
							isDisabledStatus={isDisabledStatus}
							settings={settings}
							name={name}
						/>
					</Col>
				);
			} else {
				return (
					<Col span={8} xs={24} xl={8}>
						<Form.Item
							label={settings.field_name}
							name={[name, `id-${settings.id}`, 'num_value']}
							rules={[{ required: settings.required }]}
						>
							<InputNumber
								onChange={() => onChange(settings.id, name)}
								formatter={(value) =>
									`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
								}
								parser={(x) => parseFloat(`${x}`.replace(/\s/g, ''))}
								style={{ width: '100%' }}
								disabled={isDisabledForm ? isDisabledForm : settings.readonly}
							/>
						</Form.Item>
					</Col>
				);
			}
		case 'FLOAT':
			if (settings.constant == 'cAddAttrForCar') {
				let type = true;
				for (let key in propertyValue) {
					if (
						propertyValue[key].full_name == 'Вид транспорта' &&
						propertyValue[key].num_value == 963
					) {
						type = false;
					}
				}
				return (
					<Col span={8} xs={24} xl={8}>
						<Form.Item
							label={settings.field_name}
							name={[name, `id-${settings.id}`, 'num_value']}
							rules={[{ required: settings.required }]}
							hidden={type}
							noStyle={type}
						>
							<InputNumber
								onChange={() => onChange(settings.id, name)}
								formatter={(value) =>
									`${value}`
										.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
										.replace(/\,/g, '.')
								}
								parser={(x) =>
									parseFloat(`${x}`.replace(/\s/g, '').replace(/\,/g, '.'))
								}
								style={{ width: '100%' }}
								disabled={isDisabledForm ? isDisabledForm : settings.readonly}
							/>
						</Form.Item>
					</Col>
				);
			} else if (
				settings.field_name.toLowerCase() === 'доступное кол-во дней'
			) {
				return (
					<Col span={8} xs={24} xl={8}>
						<AdminDay
							onChangeByTd={onChangeByTd}
							isAdmin={isAdmin}
							isDisabledStatus={isDisabledStatus}
							settings={settings}
							name={name}
						/>
					</Col>
				);
			} else {
				return (
					<Col span={8} xs={24} xl={8}>
						<Form.Item
							label={settings.field_name}
							name={[name, `id-${settings.id}`, 'num_value']}
							rules={[{ required: settings.required }]}
						>
							<InputNumber
								onChange={() => onChange(settings.id, name)}
								formatter={(value) =>
									`${value}`
										.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
										.replace(/\,/g, '.')
								}
								parser={(x) =>
									parseFloat(`${x}`.replace(/\s/g, '').replace(/\,/g, '.'))
								}
								style={{ width: '100%' }}
								disabled={settings.readonly}
							/>
						</Form.Item>
					</Col>
				);
			}

		case 'TIME':
			return (
				<Col span={8} xs={24} xl={8}>
					<Form.Item
						label={settings.field_name}
						name={[name, `id-${settings.id}`, 'date_value']}
						rules={[{ required: settings.required }]}
					>
						<DatePicker
							onChange={() => onChange(settings.id, name)}
							style={{ width: '100%' }}
							picker="time"
							disabled={isDisabledForm ? isDisabledForm : settings.readonly}
						/>
					</Form.Item>
				</Col>
			);
		case 'DATE':
			if (
				settings.field_name.toLowerCase() === 'дата, с' ||
				settings.field_name.toLowerCase() === 'дата, по'
			) {
				return (
					<Col span={8} xs={24} xl={8}>
						<DependentDatePicker
							settings={settings}
							name={name}
							isDisabledForm={isDisabledForm}
							onChangeByTd={onChangeByTd}
							isAdmin={isAdmin}
							isDisabledStatus={isDisabledStatus}
						/>
					</Col>
				);
			} else if (settings.field_name.toLowerCase() === 'доср. прекращ.') {
				return (
					<Col span={8} xs={24} xl={8}>
						<Form.Item
							label={settings.field_name}
							name={[name, `id-${settings.id}`, 'date_value']}
							rules={[{ required: settings.required }]}
						>
							<DatePicker
								onChange={() => onChange(settings.id, name)}
								style={{ width: '100%' }}
								format="DD.MM.YYYY"
								className="dateMask"
								disabled={isDisabledForm ? isDisabledForm : settings.readonly}
								onOpenChange={() => {
									const selector = document.querySelectorAll(
										'.ant-picker-today-btn'
									);
									selector.forEach((btn, index) => {
										const id = index + 1;
										if (!btn.hasAttribute('id'))
											btn.setAttribute('id', `date-today-${id.toString()}`);
									});
								}}
								disabledDate={(currentDate) => {
									// Запрещаем выбор даты меньше сегодняшней
									return currentDate && currentDate.isBefore(dayjs().startOf('day'));
								}}
							/>
						</Form.Item>
					</Col>
				);
			} else {
				return (
					<Col span={8} xs={24} xl={8}>
						<Form.Item
							label={settings.field_name}
							name={[name, `id-${settings.id}`, 'date_value']}
							rules={[{ required: settings.required }]}
						>
							<DatePicker
								onChange={() => onChange(settings.id, name)}
								style={{ width: '100%' }}
								format="DD.MM.YYYY"
								className="dateMask"
								disabled={isDisabledForm ? isDisabledForm : settings.readonly}
								onOpenChange={() => {
									const selector = document.querySelectorAll(
										'.ant-picker-today-btn'
									);
									selector.forEach((btn, index) => {
										const id = index + 1;
										if (!btn.hasAttribute('id'))
											btn.setAttribute('id', `date-today-${id.toString()}`);
									});
								}}
							/>
						</Form.Item>
					</Col>
				);
			}
		case 'DATETIME':
			return (
				<Col span={8} xs={24} xl={8}>
					<Form.Item
						label={settings.field_name}
						name={[name, `id-${settings.id}`, 'date_value']}
						rules={[{ required: settings.required }]}
					>
						<DatePicker
							onChange={() => onChange(settings.id, name)}
							showTime
							style={{ width: '100%' }}
							format="DD.MM.YYYY hh:mm:ss"
							disabled={isDisabledForm ? isDisabledForm : settings.readonly}
						/>
					</Form.Item>
				</Col>
			);
		case 'TEXT':
			if (settings.field_name.toLowerCase() == 'сдача в архив') {
				return (
					<Col span={8} xs={24} xl={8}>
						<Form.Item
							label={settings.field_name}
							name={[name, `id-${settings.id}`, 'char_value']}
							rules={[{ required: settings.required }]}
						>
							<Input
								onChange={
									isAdmin &&
									isDisabledStatus &&
									setTimeout(onChangeByTd(settings.id, name), 1000)
								}
								disabled={isDisabledForm ? isDisabledForm : settings.readonly}
							/>
						</Form.Item>
						<Form.Item
							label={settings.field_name}
							name={[name, `id-${settings.id}`, 'num_value']}
							rules={[{ required: settings.required }]}
							hidden
							noStyle
						>
							<Input
								onChange={() => {
									if (isAdmin && isDisabledStatus) {
										setTimeout(onChangeByTd(settings.id, name), 1000);
									}
								}}
								disabled={isDisabledForm ? isDisabledForm : settings.readonly}
							/>
						</Form.Item>
					</Col>
				);
			} else if (settings.const == 'order_remark'){
				return (
					<Col span={16} xs={24} xl={16} hidden={!['signed_ecp', 'success', 'partially_completed', 'not_fulfilled'].includes(docData?.stage?.slug)}>
						<label style={{width: '100%'}}>{settings.field_name}</label>
						<Space.Compact style={{width: '100%', marginTop: '10px'}}>
							<Form.Item
								style={{width: '90%'}}
								name={[name, `id-${settings.id}`, 'char_value']}
								rules={[{required: settings.required}]}
								hidden={!['signed_ecp', 'success', 'partially_completed', 'not_fulfilled'].includes(docData?.stage?.slug)}
							>
								<Input
									disabled={(docData?.class?.constant === 'OrderReport'
										? !isDocEditCs
										: isDisabledForm || settings.readonly)}
								/>
							</Form.Item>
							<Button
								disabled={(docData?.class?.constant === 'OrderReport'
									? !isDocEditCs
									: isDisabledForm || settings.readonly)}
								onClick={() => {
									try {
										message.success(`Вы изменили поле ${settings.field_name}`);

										if (docData.doc_row.data[0].some(row => row.editor_id === settings.id)) {
											onChangeByTd(settings.id, name)
										} else {
											const data = {
												display_no: settings.display_no,
												document_id: docData.id,
												editor_id: settings.id,
												full_name: settings.full_name,
												type: settings.type,
												char_value: form.getFieldsValue().row_column_editor[0][`id-${settings.id}`].char_value,
												document_row_id: docData.doc_row.data[0]['0'].document_row_id,
											};

											api.edo.addTabular([[data]]);
										}
									} catch (e) {
										message.error('Ошибка при изменнии поля');
									}

								}}
								style={{width: '10%'}}
								type="primary"
							>
								<EditOutlined/>
							</Button>
						</Space.Compact>
					</Col>
				)
			} else if (settings.constant === 'cKiasObjAttrType_Text') {
				if (settings.constant_editor === 'inventory_name') {
					return (
						<Col span={8} xs={24} xl={8}>
							<Form.Item
								label={settings.field_name}
								name={[name, `id-${settings.id}`, 'char_value']}
								rules={[{required: settings.required}]}
								hidden={!settings.show_column}
							>
								<Input
									onChange={() => onChange(settings.id, name)}
									disabled={true}
								/>
							</Form.Item>
						</Col>
					);
				} else if (settings.constant_editor === 'inventory_num') {
					return (
						<Col span={8} xs={24} xl={8}>
							<Form.Item
								label={settings.field_name}
								name={[name, `id-${settings.id}`, 'char_value']}
								rules={[{required: settings.required}]}
								hidden={!settings.show_column}
							>
								<Input
									onChange={() => onChange(settings.id, name)}
									disabled={true}
								/>
							</Form.Item>
						</Col>
					);
				} else {
					return (
						<Col span={8} xs={24} xl={8}>
							<Form.Item
								label={settings.field_name}
								name={[name, `id-${settings.id}`, 'char_value']}
								rules={[{required: settings.required}]}
								hidden={!settings.show_column}
							>
								<Input
									onChange={() => onChange(settings.id, name)}
									disabled={isDisabledForm ? isDisabledForm : settings.readonly}
								/>
							</Form.Item>
						</Col>
					);
				}
			} else {
				return (
					<Col span={8} xs={24} xl={8}>
						<Form.Item
							label={settings.field_name}
							name={[name, `id-${settings.id}`, 'char_value']}
							rules={[{required: settings.required}]}
							hidden={!settings.show_column}
						>
							<Input
								onChange={() => onChange(settings.id, name)}
								disabled={(docData?.class?.constant === 'OrderReport'
									? !isDocEditCs
									: isDisabledForm || settings.readonly)}
							/>
						</Form.Item>
					</Col>
				);
			}
		case 'AGREEMENT':
			if (settings.show_column) {
				return (
					<Col span={8} xs={24} xl={8}>
						<AgreementField
							name={name}
							title={settings.field_name}
							fieldID={`id-${settings.id}`}
							fieldName="char_value"
							required={settings.required}
							id={id}
							onChangeByTd={onChangeByTd}
							isAdmin={isAdmin}
							isDisabledStatus={isDisabledStatus}
						/>
					</Col>
				);
			}
			return <></>;
		case 'MULTI_SELECT':
			if (settings.show_column) {
				return (
					<Col span={8} xs={24} xl={16}>
						<Form.Item
							label={settings.field_name}
							name={[name, `id-${settings.id}`, 'json']}
							rules={[{ required: settings.required }]}
							hidden={!settings.show_column}
						>
							<TreeSelectCollegues
								colleaguesList={colleaguesList}
								multiply={true}
							/>
						</Form.Item>
					</Col>
				)
			}
			return <></>;
		default:
			return null;
	}
}

export default EdoRowColumnField;
