export const ADD_DATA = 'ADD_DATA';
export const ADD_CLASS_ID = 'ADD_CLASS_ID';
export const ADD_IIN_USER = 'ADD_IIN_USER';

export function addData(data: string) {
  return {
    type: ADD_DATA as typeof ADD_DATA,
    payload: data,
  };
}

export function addClassID(data: string) {
  return {
    type: ADD_CLASS_ID as typeof ADD_CLASS_ID,
    payload: data,
  };
}

export function addIInUser(data: string) {
  return {
    type: ADD_IIN_USER as typeof ADD_IIN_USER,
    payload: data,
  };
}
