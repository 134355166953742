/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Form, Input, Row } from 'antd';
import { useEffect, useState } from 'react';

import { ColleaguesListType } from '@hooks/useColleagues';
import useIsFirstRender from '../../../../hooks/useIsFirstRender';
import TreeSelectCollegues from '@modules/TreeSelect/TreeSelectCollegues';
import { IRowColumnEditor, IAtrributeEditor } from '@modules/Edo/types';
import api from '@modules/Edo/services/api';
import apiAgreement from '@modules/Agreement/services/api';

import { useDispatch } from 'react-redux';
import { addIInUser } from '../../../../store/Edo/actions';

interface Props {
  colleaguesList: ColleaguesListType;
  property: string;
  settings: IRowColumnEditor | IAtrributeEditor;
  name?: number;
  isDisabledForm?: boolean;
  isDouble?: boolean;
  attrForm?: any;
  id?: number;
  isVacation?: boolean;
  onChangeByTd?: any;
  isAdmin?: any;
  isDisabledStatus?: any;
}
export interface IUser {
  num_value: number;
  duty: string;
  duty_id: number;
  department: string;
  department_id: number;
  type: string;
  display_no: number;
  foreign_id: number;
  adminDay: number;
  executor_id: string;
  destination_id?: string;
}
// export interface User {
//   userList: IUser[];
// }
function FieldUser(props: Props) {
  const {
    colleaguesList = [],
    settings,
    property,
    name,
    isDisabledForm = false,
    isDouble = false,
    attrForm,
    id,
    isVacation,
    onChangeByTd,
    isAdmin,
    isDisabledStatus,
  } = props;

  const setDispatchIInUser = useDispatch();

  const [user, setUser] = useState<IUser | null>(null);
  const [docAttributeEditorUsers, setDocAttributeEditorUsers] = useState<
    any | null
  >();
  const isRowColumnEditor = typeof name === 'number';

  const form = Form.useFormInstance();
  const propertyValue = Form.useWatch(
    isRowColumnEditor
      ? ['row_column_editor', name, `id-${settings.id}`, property]
      : [`id-${settings.id}`, property],
    form
  );

  const isFirst = useIsFirstRender();

  const handleSelectUser = async (newUser) => {
    console.log((settings as IAtrributeEditor)?.attribute_type?.constant1);
    if ((settings as IAtrributeEditor)?.attribute_type?.constant1 === 'cAttrContractor' || 'cAttrSender') {
      console.log(newUser);
      setDispatchIInUser(addIInUser(newUser?.iin));
    }
    if (isDisabledStatus && isAdmin && onChangeByTd) {
      onChangeByTd(settings.id, name);
    }
    const { data } = await api.getAdminDays({ isn: newUser.foreign_id });
    setUser({ ...newUser, adminDay: data, isn: newUser.foreign_id });
  };

  useEffect(() => {
    if (propertyValue === undefined && !isFirst) {
      setUser(null);

      if (isRowColumnEditor) {
        const { row_column_editor } = form.getFieldsValue();
        if (row_column_editor[name][`id-${settings.id}`]) {
          Object.assign(row_column_editor[name][`id-${settings.id}`], {
            department: row_column_editor[name][`id-${settings.id}`].department,
            duty: row_column_editor[name][`id-${settings.id}`].duty,
          });
        }
        form.setFieldsValue({ row_column_editor });
      } else {
        form.setFieldsValue({
          [`id-${settings.id}`]: {
            department: undefined,
            duty: undefined,
          },
        });
      }
    }
  }, [propertyValue]);

  useEffect(() => {
    if (user) {
      if (isRowColumnEditor) {
        const attr = attrForm.getFieldsValue();
        const { row_column_editor } = form.getFieldsValue();
        if (!docAttributeEditorUsers) {
          Object.assign(row_column_editor[name][`id-${settings.id}`], {
            department: user.department,
            duty: user.duty,
            duty_id: user.duty_id,
            department_id: user.department_id,
            adminDay: user.adminDay,
            // destination_id: user?.destination_id,
            // executor_id: user.executor_id,
          });
        } else {
          Object.assign(row_column_editor[name][`id-${settings.id}`], {
            department: user.department,
            duty: user.duty,
            duty_id: user.duty_id,
            department_id: user.department_id,
            adminDay: user.adminDay,
          });
        }

        // При изменении ФИО в главной форме - в дочерних меняем на это значение
        if (isVacation) {
          row_column_editor.forEach((item, index) => {
            if (index > 0) {
              Object.assign(row_column_editor[index][`id-${settings.id}`], {
                department: user.department,
                duty: user.duty,
                duty_id: user.duty_id,
                department_id: user.department_id,
                adminDay: user.adminDay,
                num_value: row_column_editor[0][`id-${settings.id}`].num_value,
              });
            }
          });
        }

        form.setFieldsValue({ row_column_editor });

        if (attr) {
          const data = Object.keys(attr).map((key) => {
            if (
              attr[key]?.attribute_name === 'cAttrDestination' &&
              !attr[key].number_value &&
              user.destination_id &&
              user.executor_id
            ) {
              return Object.assign(attr[key], {
                number_value: parseInt(
                  user.destination_id ? user.destination_id : ''
                ),
                destination_id: user.destination_id,
              });
            } else if (
              attr[key]?.attribute_name === 'cAttrExecutor' &&
              !attr[key].number_value &&
              user.destination_id &&
              user.executor_id
            ) {
              return Object.assign(attr[key], {
                number_value: parseInt(user.executor_id),
                executor_id: user.executor_id,
                destination_id: user.destination_id,
              });
            }
          });
          const dataToSetFields = Object.fromEntries(
            data.map((item) => [`id-${item?.editor_id}`, item])
          );
          attrForm.setFieldsValue(dataToSetFields);
        }
      } else {
        form.setFieldsValue({
          [`id-${settings.id}`]: {
            department: user.department,
            duty: user.duty,
            foreign_id: user.foreign_id,
            adminDay: user.adminDay,
            destination_id: user.destination_id,
            executor_id: user.executor_id,
          },
        });
      }
    }
  }, [user]);
  useEffect(() => {
    if (id && attrForm && !name) {
      (async () => {
        setTimeout(async () => {
          const { data } = await apiAgreement.getAgreement(id);

          if (
            data.data.coordination.length === 0 &&
            typeof data.data.docAttributeEditorUsers == 'object'
          ) {
            if (attrForm) {
              const attr = attrForm.getFieldsValue();
              const attrData = Object.keys(attr).map((key) => {
                if (
                  attr[key]?.attribute_name === 'cAttrDestination' &&
                  !attr[key].number_value &&
                  data.data.docAttributeEditorUsers.cAttrDestination
                ) {
                  return Object.assign(attr[key], {
                    number_value: parseInt(
                      data.data.docAttributeEditorUsers.cAttrDestination.id
                        ? data.data.docAttributeEditorUsers.cAttrDestination.id
                        : ''
                    ),
                    destination_id: data.data.docAttributeEditorUsers
                      .cAttrDestination.id
                      ? data.data.docAttributeEditorUsers.cAttrDestination.id
                      : '',
                  });
                } else if (
                  attr[key]?.attribute_name === 'cAttrExecutor' &&
                  data.data.docAttributeEditorUsers.cAttrExecutor &&
                  !attr[key].number_value
                ) {
                  return Object.assign(attr[key], {
                    number_value: parseInt(
                      data.data.docAttributeEditorUsers.cAttrExecutor.id
                        ? data.data.docAttributeEditorUsers.cAttrExecutor.id
                        : ''
                    ),
                    executor_id: data.data.docAttributeEditorUsers.cAttrExecutor
                      .id
                      ? data.data.docAttributeEditorUsers.cAttrExecutor.id
                      : '',
                  });
                }
              });
              const dataToSetFields = Object.fromEntries(
                attrData.map((item) => [`id-${item?.editor_id}`, item])
              );
              attrForm.setFieldsValue(dataToSetFields);
              setDocAttributeEditorUsers(data.data.docAttributeEditorUsers);
            }
          }
        }, 1000);
      })();
    }
  }, [id, attrForm]);

  return (
    <Row gutter={15} align="bottom">
      <Col span="24">
        <Form.Item
          name={
            isRowColumnEditor
              ? [name, `id-${settings.id}`, property]
              : [`id-${settings.id}`, property]
          }
          label={
            'attribute_type' in settings
              ? settings.attribute_type.full_name
              : settings.field_name
          }
          initialValue={
            'attribute_type' in settings ? settings.def_val.val?.id : ''
          }
          rules={[{ required: settings.required }]}
        >
          <TreeSelectCollegues
            colleaguesList={colleaguesList}
            onSelectUser={handleSelectUser}
            disabled={
              isDisabledForm
                ? isDisabledForm
                : settings.readonly || (isDouble && isVacation)
            }
          />
        </Form.Item>
        <Form.Item
          name={
            isRowColumnEditor
              ? [name, `id-${settings.id}`, 'destination_id']
              : [`id-${settings.id}`, 'destination_id']
          }
          initialValue={user?.destination_id}
          hidden
          noStyle
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          name={
            isRowColumnEditor
              ? [name, `id-${settings.id}`, 'executor_id']
              : [`id-${settings.id}`, 'executor_id']
          }
          initialValue={user?.executor_id}
          hidden
          noStyle
        >
          <Input disabled />
        </Form.Item>
      </Col>
    </Row>
  );
}

export default FieldUser;
