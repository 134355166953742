import axios from '../axios';

export interface IResetDocument {
  success: boolean;
  message: string;
}

const endpoints = {
  getSettings: (data) => axios.post('document-constructor/all/setting', data),
  // TODO: Подумать над лаконичностью
  getDocument: async (documentID: string) => {
    let result;
    await axios
      .get(`document/${documentID}`)
      .then((success) => (result = success))
      .catch((error) => {
        if (error.response) {
          result = error.response;
        } else if (error.request) {
          result = error.request;
        } else {
          result = error.message;
        }
      });
    return result;
  },
  addDocument: (data) => axios.post('document', data),
  deleteDocument: (documentID) => axios.delete(`document/${documentID}`),
  addAttribute: (data) => axios.post('attribute', data),
  addTabular: (data) => axios.post('row-column', data),
  addRemark: (data) =>
    axios.put(`document/${data.document_id}`, data),

  resetDocument: (documentId: number) =>
    axios.post('/document/reset', { id: documentId }).then((val) => val.data),

  addApprovers: (data) => axios.post('docClassCoordination/list', data),
  getForm: (data: {
    document_id: number | undefined;
    document_type?: string;
  }) => axios.post('/document-note-file', data, { responseType: 'blob' }),
  addSurveyData: (data) => axios.post('/doc_form', data),
  getSurveyData: (id: number | string) => axios.get(`/doc_form/${id}`),
  updateDocumentForm: (data: any) => axios.put(`document/${data.documentId}` , data.data),
  reSendDocumentToKias: async (document_id) => {
    let result;
    await axios
      .post('document/saveToKias', {document_id, save: true})
      .then((success) => (result = success))
      .catch((error) => {
        if (error.response) {
          result = error.response;
        } else if (error.request) {
          result = error.request;
        } else {
          result = error.message;
        }
      });
    return result;
  },
  deleteRowColumn: (id) => axios.delete(`row-column/${id}`),
  getPrintBoby: (data) => axios.post('/document/print_form ', data),
  getPrintDocumentSecurities: (id) => axios.get(`document/print_form/${id}`),
  changeDocNum: (id, data) => axios.put(`/document/securities/order/${id}`, data),
  getDocumentInventory: (iin) => axios.get(`/document/inventory/${iin}`),
};

export default endpoints;
