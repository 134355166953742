import React, { useEffect, useState } from 'react';
import {
    Button,
    Card,
    Col,
    DatePicker,
    Form,
    Input,
    notification,
    Popconfirm,
    Row,
    Select,
    Space,
    Layout,
    Typography,
    Result,
    Dropdown,
    MenuProps,
    InputNumber,
    message,
} from 'antd';
import {
    ArrowLeftOutlined,
    ArrowRightOutlined,
    AuditOutlined,
    EditOutlined,
    PlusOutlined,
    PrinterFilled,
} from '@ant-design/icons';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import axios, { AxiosResponse } from 'axios';

import api from '../../services/api';
import useHandbook from '../../hooks/useHandbook';
import useColleagues from '../../hooks/useColleagues';
import useAuth from '../../hooks/useAuth';

import dayjs from 'dayjs';
import TreeSelectCollegues from '@modules/TreeSelect/TreeSelectCollegues';
import TreeSelectHandbook from '@modules/TreeSelect/TreeSelectHandook';
import EdoAgreementModal from '@modules/Edo/EdoAgreementModal';
import EdoDismissalModal from '@modules/Edo/EdoDismissalModal';
import EdoSearch from '@modules/Edo/EdoSearch';
import DateMask from '@modules/Edo/DateMask';

import { useDispatch } from 'react-redux';
import { addClassID } from '../../store/Edo/actions';
import CustomLoader from '@components/UI/CustomLoader/CustomLoader';

const { Content } = Layout;
const { Title } = Typography;

interface BusinessProcess {
    business_processes_id: number;
    class_id: number;
    id: number;
    laravel_through_key: number;
    name: string;
    role_id: number;
    type: string;
    user_id: number;
    show_the_other_doc: boolean;
}

interface IDocClassCoordination {
    class_id: number;
    created_at: string;
    created_by: number;
    document_id: number;
    employee_id: number;
    group: string;
    group_name: string;
    group_slug: string;
    id: number;
    queue: number;
    step: number;
    updated_at: string;
    updated_by: number;
}

interface IPrintFormEdoDoc {
    id: number;
    ref_id: number;
    file_type: string;
    file_path: string;
    file_name: string;
    file_remark: null;
    updated_by: number;
}

interface Props {
    title: string;
}

interface Document {
    class: {
        id: number;
        full_name: string;
        constant: string;
        company_id: number;
    };
    docClassCoordination: IDocClassCoordination[];
    business_process_sample_document: BusinessProcess[];
    class_id: number;
    client_id: number;
    date_reg: Date;
    department_id: number;
    document_id: string;
    employee_id: number;
    id: number;
    status_id: number;
    doc_order: boolean;
    docOrderCreateAccess: boolean;
    doc_orders: {
        id: number;
        doc_num: number;
        doc_id: number;
        doc_order_id: number;
        active: boolean;
        created_at: string;
        updated_at: string;
    }[];
    doc_current_order: {
        id: number;
        doc_num: number;
        doc_id: number;
        doc_order_id: number;
        active: boolean;
        created_at: string;
        updated_at: string;
    } | null;
    agreement_id: null | number;
    // show
    doc_row: any[];
    attributes: any[];
    doc_file: any[];
    remark_enabled: boolean;
    remark2: string;
    remark: string;
    remark_template: boolean;
    document_row_id: number;
    status: {
        constant: string;
    };
    stage: {
        slug: string;
    };
    stage_id: number;
    kias_err: boolean;
    active: boolean;
    prev_document_id: null | number;
    next_document_id: null | number;
    ref_document_id: null | number;
    signature_link: string;
    print_form: IPrintFormEdoDoc[];
}

interface Settings {
    id: number;
    show_client: boolean;
    show_stage: boolean;
    form_columns: any[];
    show_period: boolean;
    disable_period: boolean;
    show_date_denounce: boolean;
    show_eds: boolean;
    constant: string;
}

interface IPrintFormConverter {
    created_at: string;
    from_id: number;
    get_to_type: {
        created_at: string;
        id: number;
        type_name: string;
        updated_at: string;
    };
    id: number;
    print_form_id: number;
    to_id: number;
    type_name: string;
    updated_at: string;
}
interface ITypePrintedData {
    class_id: number;
    company_id: number;
    created_at: string;
    format_id: number;
    id: number;
    name: string;
    path: string;
    print_form_converter: IPrintFormConverter[];
    section: string;
    service: string;
    tmp_name: string;
    updated_at: string;
}

type Action = {
    type:
        | 'settings'
        | 'loadingSettings'
        | 'nextStep'
        | 'backStep'
        | 'document'
        | 'deleteDocument'
        | 'showModal'
        | 'hideModal'
        | 'showDismissalModal'
        | 'hideDismissalModal'
        | 'downloadForm'
        | 'reSendKias'
        | 'getTypeDocuments'
        | 'changeModalPrint';
    payload?: any;
};

export interface ContextType {
    docEdo: {
        data: Document;
    };
    settings: any;
    colleaguesList: any;
    handbookStatusList: any;
    handbookList?: any;
    departmentList: any[];
    remark_enabled: boolean;
}

interface State {
    settings: {
        id: number | null;
        data: Settings | null;
        isLoading: boolean;
    };

    docEdo: {
        data: Document | null;
        isLoading: boolean;
    };

    step: number;
    isVisibleModal: boolean;
    downloadForm: boolean;
    isVisibleDismissalModal: boolean;
    sendKiasStatus: boolean;
    typePrintedDocuments: {
        data: ITypePrintedData[] | null;
        isModalPrint: boolean;
    };
}

const initialState: State = {
    settings: {
        data: null,
        isLoading: false,
        id: null,
    },
    docEdo: {
        data: null,
        isLoading: false,
    },
    step: 1,
    isVisibleModal: false,
    downloadForm: false,
    isVisibleDismissalModal: false,
    sendKiasStatus: false,
    typePrintedDocuments: {
        data: null,
        isModalPrint: false,
    },
};

const reducer = (draft = initialState, action: Action) => {
    switch (action.type) {
        case 'settings': {
            draft.settings.data = action.payload;
            break;
        }
        case 'loadingSettings': {
            draft.settings.isLoading = action.payload;
            break;
        }
        case 'nextStep': {
            draft.step = action.payload;
            break;
        }
        case 'document': {
            draft.docEdo.data = action.payload;
            draft.sendKiasStatus = action.payload.kias_err;
            break;
        }
        case 'backStep': {
            draft.step = draft.step - 1;
            draft.docEdo.data = null;
            draft.settings.data = null;
            break;
        }
        case 'reSendKias': {
            draft.sendKiasStatus = action.payload;
            break;
        }
        // case 'deleteDocument': {
        //   break;
        // }
        case 'showDismissalModal': {
            draft.isVisibleDismissalModal = true;
            break;
        }
        case 'showModal': {
            draft.isVisibleModal = true;
            break;
        }
        case 'hideDismissalModal': {
            draft.isVisibleDismissalModal = false;
            break;
        }
        case 'hideModal': {
            draft.isVisibleModal = false;
            break;
        }
        case 'downloadForm': {
            draft.downloadForm = action.payload;
            break;
        }
        case 'getTypeDocuments': {
            draft.typePrintedDocuments.data = action.payload;
            break;
        }
        case 'changeModalPrint': {
            draft.typePrintedDocuments.isModalPrint = action.payload;
            break;
        }
        default:
            throw new Error(`Unknown action type: ${action.type}`);
    }
};

const arraySecuritiesDoc: string[] = [
    'AffReport',
    'RecReport',
    'ConcReport',
    'ProtReport',
    'OrderReport',
];

const checkSecuritiesDoc = (array, value) => {
    return array.includes(value);
};

function EdoPage({ title = 'Mycent.kz' }: Props) {
    const auth = useAuth();
    const [state, dispatch] = useImmerReducer(reducer, initialState);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const {
        settings,
        step,
        docEdo,
        isVisibleModal,
        isVisibleDismissalModal,
        sendKiasStatus,
    } = state;

    const [form] = Form.useForm();
    const [hasSurvey, setHasSurvey] = useState(false);
    const { handbookList, isLoadingHandbookList } = useHandbook('cDocClass');
    const {
        handbookList: handbookStatusList,
        isLoadingHandbookList: isLoadingStatusList,
        handbookStageList: handbookStageList,
        isLoadingHandbookStageList: isLoadingStageList,
    } = useHandbook('cDocStatus');

    const { colleaguesList, departmentList, isLoadingColleaguesList } =
        useColleagues();
    const { documentID } = useParams();
    const [access, setAccess] = useState(true);
    const [dateBeg, setDateBeg] = useState('');
    const [dateEnd, setDateEnd] = useState('');
    const [items, setItems] = useState<MenuProps['items']>([]);
    const setDispatchClassID = useDispatch();

    let navigate = useNavigate();

    const fetchData = async (id) => {
        try {
            dispatch({ type: 'loadingSettings', payload: true });
            const { data } = await api.edo.getDocument(id);

            await console.log('data --- > ', data);

            setDispatchClassID(addClassID(data));

            // console.log('class_id', data.data.class.id);

            // Если получаем статус 403 - запрещаем просмотр документа
            setAccess(!(data.error_code === 403));

            if (data.data.class.constant1 == 'cAppForDismissal') {
                const { data } = await api.edo.getSurveyData(id);
                setHasSurvey(data.data.length > 0);
            }
            if (settings.data === null) {
                const { data: settingsData } = await api.edo.getSettings({
                    id: data.data.class.id,
                });
                dispatch({ type: 'settings', payload: settingsData.data });
            }

            dispatch({ type: 'document', payload: data.data });

            form.setFieldsValue({
                document_id: data.data.document_id,
                class_id: data.data.class.id,
                status_id: data.data.status.foreign_id,
                // TODO - удалить (не используем стадию прохождения)
                stage_id: data.data.stage.id,
                id: data.data.id,
                employee_id: data.data.employee_id.id,
                department_id: data.data.department,
                date_reg: dayjs(data.data.date_reg),
                date_end: data.data.date_end ? dayjs(data.data.date_end) : '',
                date_beg: data.data.date_beg ? dayjs(data.data.date_beg) : '',
                date_denounce: data.data.date_denounce
                    ? dayjs(data.data.date_denounce)
                    : '',
                client_id: data.data.client.client_id,
                doc_num: data?.data?.doc_current_order?.doc_num,
            });
        } catch (error) {
        } finally {
            dispatch({ type: 'loadingSettings', payload: false });
        }
    };

    const getExtensionFromMimeType = (mimeType) => {
        switch (mimeType) {
            case 'application/msword':
                return 'doc';
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                return 'docx';
            case 'application/pdf':
                return 'pdf';
            case 'image/png':
                return 'png';
            case 'image/jpeg':
                return 'jpeg';
            case 'image/jpg':
                return 'jpg';
            case 'image/webp':
                return 'webp';
            case 'image/gif':
                return 'gif';
            case 'image/tiff':
                return 'tiff';
            case 'application/vnd.ms-excel':
                return 'xls';
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                return 'xlsx';
            case 'application/vnd.ms-excel.sheet.macroEnabled.12':
                return 'xlsm';
            case 'video/mp4':
                return 'mp4';
            case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
                return 'pptx';
            default:
                return '';
        }
    };

    const downloadFile = async (itemId) => {
        try {
            dispatch({ type: 'downloadForm', payload: true });
            const requestData = {
                document_id: docEdo?.data?.id,
                convert_id: itemId,
            };

            const documentBody = await api.edo.getPrintBoby(requestData);
            const documentData = await axios.post(
                'https://print.mycent.kz/api/print_form_converter/temp',
                documentBody.data,
                {
                    responseType: 'blob',
                }
            );

            const downloadUrl = window.URL.createObjectURL(
                new Blob([documentData.data])
            );
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute(
                'download',
                `${documentBody.data.temp_name}.${getExtensionFromMimeType(
                    documentData.data.type
                )}`
            );
            link.click();
            link.remove();
        } catch (error) {
            let message;

            if (axios.isAxiosError(error) && error.response) {
                message = error.response.data.message;
            } else {
                message = String(error);
            }

            notification.info({
                message: 'Ошибка',
                description: message,
            });
        } finally {
            dispatch({ type: 'downloadForm', payload: false });
        }
    };

    const handleClickPrint = async () => {
        try {
            dispatch({ type: 'downloadForm', payload: true });
            const response = await axios.post(
                'https://print.mycent.kz/api/print_form/search',
                { class_id: docEdo.data?.class?.id }
            );
            dispatch({ type: 'getTypeDocuments', payload: response.data.data });
            setItems(
                response?.data?.data[0]?.print_form_converter.map((item) => {
                    return {
                        label: (
                            <a onClick={() => downloadFile(item.id)}>{item.type_name}</a>
                        ),
                        key: item.id,
                    };
                })
            );
        } catch (error) {
            let message;

            if (axios.isAxiosError(error) && error.response) {
                message = error.response.data.message;
            } else {
                message = String(error);
            }

            notification.info({
                message: 'Ошибка',
                description: message,
            });
        } finally {
            dispatch({ type: 'downloadForm', payload: false });
        }
    };

    const printSecurities = async () => {
        try {
            dispatch({ type: 'downloadForm', payload: true });

            const documentBody = await api.edo.getPrintDocumentSecurities(documentID);

            const byteCharacters = atob(documentBody?.data?.data?.file);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const fileBlob = new Blob([byteArray], {
                type: 'application/pdf;base64',
            });
            const downloadUrl = URL.createObjectURL(fileBlob);

            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', `${documentBody?.data?.data?.file_name}`);
            link.click();
            link.remove();
        } catch (error) {
            let message;

            if (axios.isAxiosError(error) && error.response) {
                message = error.response.data.message;
            } else {
                message = String(error);
            }

            notification.info({
                message: 'Ошибка',
                description: message,
            });
        } finally {
            dispatch({ type: 'downloadForm', payload: false });
        }
    };

    const reSendDocument = () => {
        (async () => {
            if (state.docEdo.data?.id) {
                const { data } = await api.edo.reSendDocumentToKias(
                    state.docEdo.data.id
                );
                dispatch({ type: 'reSendKias', payload: !data.success });
            }
        })();
    };

    const handleDocumentSettings = () => {
        form.validateFields().then(async (values) => {
            try {
                dispatch({ type: 'loadingSettings', payload: true });

                const { data } = await api.edo.getSettings({
                    id: values.id,
                });

                form.setFieldsValue({
                    class_id: data.data.id,
                    status_id: 2516,
                    stage_id: 1,
                    employee_id: auth.user.data?.user_info.id,
                    department_id: auth.user.data?.department?.id,
                });

                const excludedIds = [
                    7645, 915, 7809, 7799, 7808, 7802, 7803, 7807, 7806, 7797, 7804, 7798,
                    7811, 7810,
                ];

                if (!excludedIds.includes(data.data.id)) {
                    setTimeout(() => {
                        handleDocumentCreate(form.getFieldsValue());
                    }, 500);
                }

                dispatch({ type: 'settings', payload: data.data });
                dispatch({ type: 'nextStep', payload: 2 });
            } catch (error) {
            } finally {
                dispatch({ type: 'loadingSettings', payload: false });
            }
        });
    };

    const handleDocumentCreate = async (values) => {
        values.employee_id = parseInt(values.employee_id);

        try {
            const sendData = {
                // TODO на бэкенде сделать update если есть id
                id: docEdo.data?.id,
                class_id: values.class_id,
                date_reg: values.date_reg,
                date_beg: values.date_beg,
                date_end: values.date_end,
                date_denounce: values.date_denounce,
                department_id: values.department_id,
                employee_id: values.employee_id,
                // TODO - удалить (не используем стадию прохождения)
                stage_id: values.stage_id,
                status_id: values.status_id,
                client_id: parseInt(values.client_id),
            };

            const { data } = await api.edo.addDocument(sendData);

            dispatch({ type: 'document', payload: data });

            form.setFieldsValue({
                document_id: data.document_id,
                stage_id: parseInt(data.stage_id),
                status_id: parseInt(data.status_id),
            });

            // setId(data.id);
            navigate(`${data.id}`);
        } catch (error) {
            let message;

            if (axios.isAxiosError(error)) {
                if (error.response?.status === 422) {
                    Object.keys(error.response.data).forEach((key) => {
                        form.setFields([
                            {
                                name: key,
                                // @ts-ignore
                                errors: error.response.data[key],
                            },
                        ]);
                    });

                    return;
                }

                message = error.message;

                if (error.response?.status === 404 && error.response) {
                    message = error.response.data.error;
                }
            } else {
                message = String(error);
            }

            notification.info({
                message: 'Ошибка',
                description: message,
            });
        }
    };

    function handleSubmitAttributeForm(values) {
        const sendData = Object.values(values);

        sendData.forEach((item: any) => {
            if (typeof item.number_value === 'string') {
                item.number_value = parseInt(item.number_value);
            }
        });

        return api.edo.addAttribute(sendData);
    }

    function handleSubmitTabularForm(values) {
        let sendData = JSON.parse(JSON.stringify(values));
        sendData = Object.values(sendData.row_column_editor).map((item: any) => {
            const itemArray = Object.values(item);

            itemArray.forEach((item: any) => {
                if (typeof item.num_value === 'string') {
                    item.num_value = parseInt(item.num_value);
                }

                /**
                 * При автоматической конвертации в JSON часовой пояс игнорируется,
                 * дата сбивается на 1 день назад
                 * Поэтому конвертируем в строку средствами dayjs
                 */
                if (item.date_value && typeof item.date_value === 'string') {
                    if (dayjs(item.date_value).isValid()) {
                        item.date_value = dayjs(item.date_value).format();
                    }
                }
            });
            return Object.values(item);
        });

        return api.edo.addTabular(sendData);
    }

    function handleSubmitRemarkForm(values) {
        let sendData = JSON.parse(JSON.stringify(values));
        return api.edo.addRemark({
            document_id: values.remark.document_id,
            remark: values.remark.remark,
            remark2: values.remark2 ? values.remark2.remark2 : '',
        });
    }

    const onCancel = () => {
        window.location.reload();
        dispatch({ type: 'hideModal' });
    };

    useEffect(() => {
        document.title = title;
    }, [title]);

    useEffect(() => {
        /*
                Просмотр документа
                Получаем данные документа и настройки домукента
            */
        if (documentID) {
            // settings.data === null && documentID
            dispatch({ type: 'nextStep', payload: 2 });
            fetchData(documentID);
        }
    }, [documentID]);

    if (
        isLoadingHandbookList ||
        isLoadingStatusList ||
        isLoadingColleaguesList ||
        isLoadingStageList
    ) {
        return <CustomLoader />;
    } else {
        if (!settings.isLoading) {
            DateMask();
        }
    }

    const sendAllData = async (forms: any) => {
        try {
            const attributesForm = forms.attributes;
            const tabularForm = forms.tabular;
            const remarkForm = forms.remark;
            const remark2Form = forms.remark2;
            const documentForm = forms.documentForm;

            if (typeof remarkForm !== 'undefined') {
                await handleSubmitRemarkForm({
                    remark: remarkForm.getFieldsValue(),
                });
                if (typeof remark2Form !== 'undefined') {
                    await handleSubmitRemarkForm({
                        remark: remarkForm.getFieldsValue(),
                        remark2: remark2Form.getFieldsValue(),
                    });
                }
            }
            if (typeof attributesForm !== 'undefined') {
                const { data: dataAttributes } = await handleSubmitAttributeForm(
                    attributesForm.getFieldsValue()
                );

                const attributesToSetFields = Object.fromEntries(
                    dataAttributes?.map((item) => [
                        `id-${item.editor_id}`,
                        {
                            ...item,
                            date_value: dayjs(item.date_value).isValid()
                                ? dayjs(item.date_value)
                                : null,
                        },
                    ])
                );

                attributesForm.setFieldsValue(attributesToSetFields);
            }
            if (typeof tabularForm !== 'undefined') {
                const { data: dataTabular } = await handleSubmitTabularForm(
                    tabularForm.getFieldsValue()
                );
                const dataTabularArray = dataTabular.flat(2);

                for (
                    let i = 0;
                    i < tabularForm.getFieldsValue().row_column_editor.length;
                    i++
                ) {
                    for (const propName in tabularForm.getFieldsValue().row_column_editor[
                        i
                        ]) {
                        const orderNo =
                            tabularForm.getFieldsValue().row_column_editor[i][propName]
                                .order_no;
                        const displayNo =
                            tabularForm.getFieldsValue().row_column_editor[i][propName]
                                .display_no;

                        const findElement = dataTabularArray.find(
                            (item) =>
                                parseInt(item.display_no) === displayNo &&
                                parseInt(item.order_no) === orderNo
                        );

                        if (findElement) {
                            Object.assign(
                                tabularForm.getFieldsValue().row_column_editor[i][propName],
                                {
                                    id: parseInt(findElement.id),
                                }
                            );
                            tabularForm.setFieldsValue(tabularForm.getFieldsValue());
                        }
                    }
                }
            }
            // + (':00:00.000Z');
            if (typeof documentForm !== 'undefined') {
                const documentFormData = documentForm.getFieldsValue();
                const data = api.edo
                    .updateDocumentForm({
                        documentId: documentID,
                        data: {
                            ...documentFormData,
                            date_reg: dayjs(documentFormData.date_reg).format('YYYY-MM-DD'),
                            date_beg: dayjs(documentFormData.date_beg).format('YYYY-MM-DD'),
                            date_end: dayjs(documentFormData.date_end).format('YYYY-MM-DD'),
                            date_denounce: dayjs(documentFormData?.date_denounce).format(
                                'YYYY-MM-DD'
                            ),
                        },
                    })
                    .catch((error) => {
                        let message;

                        if (axios.isAxiosError(error)) {
                            if (
                                error.response &&
                                error.response.data &&
                                error.response.data.message
                            ) {
                                message = error.response.data.message;
                            } else {
                                message = error.message;
                            }
                        } else {
                            message = String(error);
                        }

                        notification.info({
                            message: 'Ошибка',
                            description: message,
                        });

                        console.error('Ошибка:', message);
                    });
            }
        } catch (error) {
            let message;

            if (axios.isAxiosError(error)) {
                message = error.message;
            } else {
                message = String(error);
            }

            notification.info({
                message: 'Ошибка',
                description: message,
            });
        }
    };
    const selectBegDate = (val) => {
        setDateBeg(val);
    };
    const selectEndDate = (val) => {
        setDateEnd(val);
    };
    const isTd =
        (auth?.user?.data?.permission?.access_role &&
            Object.keys(auth?.user?.data?.permission?.access_role).filter((key) => {
                if (auth?.user?.data?.permission?.access_role[key].slug === 'td') {
                    return auth?.user?.data?.permission?.access_role[key];
                }
            }).length > 0) ||
        auth?.user?.data?.permission?.is_admin;

    const changeDocNum = async () => {
        const docNum = form.getFieldValue('doc_num');

        if (!docNum || docNum.trim() === '') {
            notification.error({
                message: 'Ошибка',
                description: 'Порядковый номер приказа не может быть пустым.',
            });
            return;
        }

        try {
            await api.edo.changeDocNum(docEdo?.data?.doc_current_order?.id, {
                doc_num: docNum,
            });

            message.success('Вы поменяли порядковый номер приказа');
        } catch (error) {
            let errorMessage;

            if (axios.isAxiosError(error) && error.response) {
                errorMessage = error.response.data.message;
            } else {
                errorMessage = String(error);
            }

            notification.info({
                message: 'Ошибка',
                description: errorMessage,
            });
        }
    };

    return access ? (
        <>
            <Form.Provider
                onFormFinish={async (name, { values, forms }) => {
                    if (documentID) {
                        const { data } = await api.edo.getDocument(`${documentID}`);
                        if (name === 'listForm') {
                            Promise.all(
                                Object.values(forms).map((form) => form.validateFields())
                            )
                                .then(async () => {
                                    if (data.data.class.constant1 !== 'cAppForDismissal') {
                                        sendAllData(forms);
                                        dispatch({ type: 'showModal' });
                                    } else if (
                                        data.data.class.constant1 == 'cAppForDismissal' &&
                                        !hasSurvey
                                    ) {
                                        if (
                                            settings.data?.form_columns &&
                                            settings.data?.form_columns.length > 0
                                        ) {
                                            dispatch({ type: 'showDismissalModal' });
                                            sendAllData(forms);
                                        } else {
                                            dispatch({ type: 'showModal' });
                                        }
                                    } else {
                                        dispatch({ type: 'showModal' });
                                        sendAllData(forms);
                                    }
                                })
                                .catch((e) => {
                                    notification.info({
                                        message: 'Уведомление',
                                        description: 'Заполните обязательные поля',
                                    });
                                });
                        }
                    }

                    if (docEdo?.data?.id === docEdo?.data?.ref_document_id) {
                        const filteredData = docEdo.data?.business_process_sample_document
                            .filter((item) => !item.show_the_other_doc)
                            .map((element) => {
                                return {
                                    class_id: element.class_id,
                                    document_id: docEdo?.data?.id,
                                    employee_id: element.user_id,
                                    group: element.type,
                                    group_name: element.name,
                                    queue: 1,
                                    step: 1,
                                };
                            });

                        if (forms.approvalSheet && filteredData) {
                            const { secretary, signatory } =
                                forms.approvalSheet.getFieldsValue();

                            const requestData = [...secretary, ...signatory, ...filteredData];

                            const requestDataForClassCoordination = [
                                ...secretary,
                                ...signatory,
                            ];

                            try {
                                await api.edo.addApprovers(
                                    docEdo.data?.docClassCoordination.length
                                        ? requestDataForClassCoordination
                                        : requestData
                                );
                            } catch (error) {
                                let message;
                                if (axios.isAxiosError(error)) {
                                    message = error.message;
                                } else {
                                    message = String(error);
                                }
                                notification.info({
                                    message: 'Ошибка',
                                    description: message,
                                });
                            }
                        }
                    }
                }}
            >
                <CustomLoader spinning={settings.isLoading}>
                    <Content data-testid="edo-content">
                        <Card>
                            {step === 2 && (
                                <Row
                                    data-testid="edo-row"
                                    gutter={25}
                                    justify="space-between"
                                    align="middle"
                                    className="mb-5"
                                >
                                    <Col span={6}>
                                        <Button
                                            data-testid="edo-comeback"
                                            icon={<ArrowLeftOutlined />}
                                            onClick={() => {
                                                dispatch({ type: 'backStep' });
                                                navigate('/document');
                                                form.resetFields();
                                            }}
                                        >
                                            Назад к списку документов
                                        </Button>
                                    </Col>
                                    <Row align="middle">
                                        <Space>
                                            {docEdo?.data?.signature_link && (
                                                <Col>
                                                    <Button
                                                        type="primary"
                                                        href={docEdo.data?.signature_link}
                                                        target="_blank"
                                                    >
                                                        Проверить подпись
                                                    </Button>
                                                </Col>
                                            )}
                                            {docEdo?.data?.ref_document_id &&
                                                docEdo?.data?.id !== docEdo?.data?.ref_document_id && (
                                                    <Col>
                                                        <Button
                                                            type="primary"
                                                            icon={<AuditOutlined />}
                                                            href={`/document/${docEdo.data?.ref_document_id}`}
                                                        >
                                                            Инвест. рекомендация
                                                        </Button>
                                                    </Col>
                                                )}
                                            {docEdo?.data?.doc_current_order?.doc_id && (
                                                <Col>
                                                    <Button
                                                        type="primary"
                                                        icon={<AuditOutlined />}
                                                        href={`/document/${docEdo?.data?.doc_current_order?.doc_id}`}
                                                    >
                                                        Инвест. Решение
                                                    </Button>
                                                </Col>
                                            )}
                                            {docEdo?.data?.agreement_id && (
                                                <Col>
                                                    <Button
                                                        type="primary"
                                                        icon={<AuditOutlined />}
                                                        href={`/document/${docEdo?.data?.agreement_id }`}
                                                    >
                                                        Протокол заседания
                                                    </Button>
                                                </Col>
                                            )}
                                            {docEdo?.data?.prev_document_id && (
                                                <Col>
                                                    <Button
                                                        type="default"
                                                        icon={<ArrowLeftOutlined />}
                                                        href={`/document/${docEdo.data?.prev_document_id}`}
                                                    >
                                                        Предыдущий документ
                                                    </Button>
                                                </Col>
                                            )}
                                            {docEdo?.data?.next_document_id && (
                                                <Col>
                                                    <Button
                                                        type="default"
                                                        icon={<ArrowRightOutlined />}
                                                        href={`/document/${docEdo.data?.next_document_id}`}
                                                    >
                                                        Следующий документ
                                                    </Button>
                                                </Col>
                                            )}
                                            <Col>
                                                {docEdo.data?.print_form ? (
                                                    <Button
                                                        icon={<PrinterFilled />}
                                                        type="primary"
                                                        onClick={printSecurities}
                                                    >
                                                        Печатная форма
                                                    </Button>
                                                ) : (
                                                    <Dropdown menu={{ items }} trigger={['click']}>
                                                        <Button
                                                            onClick={handleClickPrint}
                                                            icon={<PrinterFilled />}
                                                            type="primary"
                                                            loading={state.downloadForm}
                                                        >
                                                            Печатная форма
                                                        </Button>
                                                    </Dropdown>
                                                )}
                                            </Col>
                                        </Space>
                                    </Row>
                                </Row>
                            )}

                            <Title
                                data-testid="edo-title"
                                level={5}
                                type="secondary"
                                className="mb-5"
                            >
                                Новый документ
                            </Title>
                            <Form
                                layout="vertical"
                                name="documentForm"
                                form={form}
                                onFinish={handleDocumentCreate}
                            >
                                {step === 1 && (
                                    <Row gutter={24} align="bottom">
                                        <Col span={8} xs={24} xl={8}>
                                            <Form.Item
                                                data-testid="type-document"
                                                label="Тип документа"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Обязательное поле',
                                                    },
                                                ]}
                                                name="id"
                                            >
                                                <TreeSelectHandbook handbookList={handbookList} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Button
                                                data-testid="edo-button-create"
                                                type="primary"
                                                icon={<PlusOutlined />}
                                                onClick={handleDocumentSettings}
                                                loading={settings.isLoading}
                                            >
                                                Создать
                                            </Button>
                                        </Col>
                                    </Row>
                                )}

                                {step === 2 && (
                                    <Row gutter={24} align="bottom">
                                        <Col span={8} xs={24} xl={8}>
                                            <Form.Item
                                                label="Тип документа"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Обязательное поле',
                                                    },
                                                ]}
                                                name="class_id"
                                            >
                                                <TreeSelectHandbook
                                                    handbookList={handbookList}
                                                    disabled={true}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={8} xs={24} xl={8}>
                                            <Form.Item label="ID документа" name="id" hidden>
                                                <Input disabled />
                                            </Form.Item>
                                            <Form.Item label="Номер документа №" name="document_id">
                                                <Input disabled />
                                            </Form.Item>
                                        </Col>
                                        {settings?.data?.constant === 'OrderReport' && (
                                            <Col span={8} xs={24} xl={8}>
                                                <label style={{ width: '100%' }}>
                                                    Порядковый номер приказа
                                                </label>
                                                <Space.Compact
                                                    style={{ width: '100%', marginTop: '10px' }}
                                                >
                                                    <Form.Item name="doc_num" style={{ width: '80%' }}>
                                                        <Input
                                                            onChange={(e) => {
                                                                let input = e.target.value.replace(/\D/g, '');
                                                                form.setFieldValue('doc_num', input);
                                                            }}
                                                            disabled={
                                                                docEdo?.data?.stage?.slug.toLowerCase() ===
                                                                'signed_ecp'
                                                            }
                                                        />
                                                    </Form.Item>
                                                    <Button
                                                        onClick={changeDocNum}
                                                        style={{ width: '20%' }}
                                                        type="primary"
                                                        disabled={
                                                            docEdo?.data?.stage?.slug.toLowerCase() ===
                                                            'signed_ecp'
                                                        }
                                                    >
                                                        <EditOutlined />
                                                    </Button>
                                                </Space.Compact>
                                            </Col>
                                        )}
                                        {/* <Col span={8}> */}
                                        <Form.Item label="Статус" name="status_id" hidden noStyle>
                                            <Select
                                                options={handbookStatusList}
                                                showSearch
                                                allowClear
                                                disabled
                                                fieldNames={{
                                                    label: 'full_name',
                                                    value: 'foreign_id',
                                                    options: 'children',
                                                }}
                                            />
                                        </Form.Item>
                                        {/* </Col> */}
                                        {settings.data?.show_stage && (
                                            <Col span={8} xs={24} xl={8}>
                                                <Form.Item label="Статус" name="stage_id">
                                                    <Select
                                                        options={handbookStageList}
                                                        showSearch
                                                        allowClear
                                                        disabled
                                                        fieldNames={{
                                                            label: 'name',
                                                            value: 'id',
                                                            options: 'children',
                                                        }}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        )}

                                        <Col
                                            span={8}
                                            xs={24}
                                            xl={8}
                                            hidden={checkSecuritiesDoc(
                                                arraySecuritiesDoc,
                                                settings?.data?.constant
                                            )}
                                        >
                                            <Form.Item label="Куратор" name="employee_id">
                                                <TreeSelectCollegues
                                                    disabled={true}
                                                    colleaguesList={colleaguesList}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col
                                            span={8}
                                            xs={24}
                                            xl={8}
                                            hidden={checkSecuritiesDoc(
                                                arraySecuritiesDoc,
                                                settings?.data?.constant
                                            )}
                                        >
                                            <Form.Item label="Подразделение" name="department_id">
                                                <TreeSelectCollegues
                                                    disabled={true}
                                                    selectDepartment={true}
                                                    colleaguesList={departmentList}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={8} xs={24} xl={8}>
                                            <Form.Item
                                                label="Дата регистрации"
                                                name="date_reg"
                                                initialValue={dayjs()}
                                                required
                                            >
                                                <DatePicker
                                                    className="dateMask"
                                                    style={{ width: '100%' }}
                                                    format={'DD.MM.YYYY'}
                                                    // disabledDate={(current) => {
                                                    //   return (
                                                    //     dayjs().add(+1, 'days') >= current ||
                                                    //     dayjs().add(1, 'month') <= current
                                                    //   );
                                                    // }}
                                                    disabled={isTd ? !isTd : !!docEdo.data}
                                                />
                                            </Form.Item>
                                        </Col>
                                        {settings.data?.show_client && (
                                            <Col span={8} xs={24} xl={8}>
                                                <Form.Item label="Контрагент" name="client_id">
                                                    <TreeSelectCollegues
                                                        disabled={isTd ? !isTd : !!docEdo.data}
                                                        colleaguesList={colleaguesList}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        )}
                                        {settings.data?.show_period && (
                                            <>
                                                <Col span={8} xs={24} xl={8}>
                                                    <Form.Item
                                                        label="Дата начала"
                                                        name="date_beg"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Обязательное поле',
                                                            },
                                                        ]}
                                                    >
                                                        {settings.data.id == 7808 ||
                                                        settings.data.id == 7809 ||
                                                        settings.data.id == 7810 ||
                                                        settings.data.id == 7811 ? (
                                                            <DatePicker
                                                                className="dateMask"
                                                                style={{ width: '100%' }}
                                                                format="DD.MM.YYYY"
                                                                onChange={selectBegDate}
                                                                disabled={isTd ? !isTd : !!docEdo.data}
                                                            />
                                                        ) : (
                                                            <DatePicker
                                                                className="dateMask"
                                                                style={{ width: '100%' }}
                                                                format="DD.MM.YYYY"
                                                                onChange={selectBegDate}
                                                                disabledDate={(current) => {
                                                                    return (
                                                                        dayjs(docEdo?.data?.date_reg).add(
                                                                            -1,
                                                                            'day'
                                                                        ) >= current
                                                                    );
                                                                }}
                                                                disabled={isTd ? !isTd : !!docEdo.data}
                                                            />
                                                        )}
                                                    </Form.Item>
                                                </Col>
                                                <Col span={8} xs={24} xl={8}>
                                                    <Form.Item
                                                        label="Дата окончания"
                                                        name="date_end"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Обязательное поле',
                                                            },
                                                        ]}
                                                    >
                                                        <DatePicker
                                                            className="dateMask"
                                                            style={{ width: '100%' }}
                                                            format="DD.MM.YYYY"
                                                            onChange={selectEndDate}
                                                            disabledDate={(current) => {
                                                                if (typeof dateBeg == 'string') {
                                                                    return (
                                                                        dayjs(docEdo?.data?.date_reg).add(
                                                                            -1,
                                                                            'day'
                                                                        ) >= current
                                                                    );
                                                                } else if (
                                                                    dateBeg !== null ||
                                                                    typeof dateBeg == 'object'
                                                                ) {
                                                                    return (
                                                                        dayjs(dateBeg).startOf('day') >
                                                                        dayjs(current).startOf('day')
                                                                    );
                                                                } else {
                                                                    return (
                                                                        dayjs(docEdo?.data?.date_reg).add(-2) >=
                                                                        current
                                                                    );
                                                                }
                                                            }}
                                                            disabled={isTd ? !isTd : !!docEdo.data}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </>
                                        )}
                                        {settings.data?.show_date_denounce && (
                                            <Col span={8} xs={24} xl={8}>
                                                <Form.Item
                                                    label="Доср. прекращ."
                                                    name="date_denounce"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Обязательное поле',
                                                        },
                                                    ]}
                                                >
                                                    <DatePicker
                                                        className="dateMask"
                                                        style={{ width: '100%' }}
                                                        format={'DD.MM.YYYY'}
                                                        disabledDate={(current) => {
                                                            if (typeof dateBeg == 'string') {
                                                                return (
                                                                    dayjs(docEdo?.data?.date_reg).add(
                                                                        -1,
                                                                        'day'
                                                                    ) >= current
                                                                );
                                                            } else if (
                                                                dateBeg !== null ||
                                                                typeof dateBeg == 'object'
                                                            ) {
                                                                return (
                                                                    dayjs(dateBeg).startOf('day') >
                                                                    dayjs(current).startOf('day')
                                                                );
                                                            } else {
                                                                return (
                                                                    dayjs(docEdo?.data?.date_reg).add(-2) >=
                                                                    current
                                                                );
                                                            }
                                                        }}
                                                        disabled={isTd ? !isTd : !!docEdo.data}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        )}
                                        {docEdo.data === null &&
                                            (settings.data?.id === 7645 ||
                                                settings.data?.id === 915 ||
                                                settings.data?.id === 7809 ||
                                                settings.data?.id === 7799 ||
                                                settings.data?.id === 7808 ||
                                                settings.data?.id === 7802 ||
                                                settings.data?.id === 7803 ||
                                                settings.data?.id === 7807 ||
                                                settings.data?.id === 7806 ||
                                                settings.data?.id === 7797 ||
                                                settings.data?.id === 7804 ||
                                                settings.data?.id === 7798 ||
                                                settings.data?.id === 7811 ||
                                                settings.data?.id === 7810) && (
                                                <Col span={24}>
                                                    <Button
                                                        htmlType="submit"
                                                        type="primary"
                                                        disabled={!!docEdo.data}
                                                    >
                                                        Сохранить и продолжить
                                                    </Button>
                                                </Col>
                                            )}
                                        {sendKiasStatus &&
                                            (auth.user.data.permission.is_admin ||
                                                Object.keys(
                                                    auth?.user?.data?.permission?.access_role
                                                ).filter(
                                                    (key) =>
                                                        auth?.user?.data?.permission?.access_role[
                                                            key
                                                            ].slug.toLowerCase() === 'td'
                                                ).length > 0) && (
                                                <Col span={24}>
                                                    <Button
                                                        type="primary"
                                                        onClick={reSendDocument}
                                                        loading={settings.isLoading}
                                                    >
                                                        Отправить повторно
                                                    </Button>
                                                </Col>
                                            )}
                                    </Row>
                                )}
                            </Form>
                        </Card>

                        {step === 1 && (
                            <div className="mt-5">
                                <EdoSearch
                                    handbookList={handbookList}
                                    handbookStatusList={handbookStatusList}
                                    handBookStageList={handbookStageList}
                                    departmentList={departmentList}
                                    colleaguesList={colleaguesList}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                />
                            </div>
                        )}

                        {step === 2 && docEdo.data !== null && settings.data !== null && (
                            <>
                                <Outlet
                                    context={{
                                        docEdo,
                                        settings: settings.data,
                                        handbookStatusList,
                                        handbookStageList,
                                        handbookList,
                                        colleaguesList,
                                        departmentList,
                                    }}
                                />
                            </>
                        )}
                    </Content>
                </CustomLoader>
            </Form.Provider>
            {settings.data?.form_columns &&
                settings.data?.form_columns.length > 0 &&
                !hasSurvey && (
                    <EdoDismissalModal
                        data={settings.data?.form_columns}
                        visible={isVisibleDismissalModal}
                        documentID={docEdo.data?.id}
                        onCancel={() => dispatch({ type: 'hideDismissalModal' })}
                        onSend={() => dispatch({ type: 'showModal' })}
                    />
                )}

            <EdoAgreementModal
                colleaguesList={colleaguesList}
                handbookStatusList={handbookStatusList}
                documentID={docEdo.data?.id}
                visible={isVisibleModal}
                onCancel={onCancel}
                onSend={() => fetchData(documentID)}
                settings={settings.data}
                classConstant={docEdo?.data?.class?.constant}
                classId={docEdo.data?.class?.id}
            />
        </>
    ) : (
        <Result
            status="warning"
            title="У Вас нет прав для доступа к этой странице"
            extra={
                <Button type="primary" key="console" onClick={() => navigate(-1)}>
                    Вернуться назад
                </Button>
            }
        />
    );
}

export default EdoPage;
