import { useEffect } from 'react';
import { useImmerReducer } from 'use-immer';

import { ColleaguesListType } from '../../../hooks/useColleagues';
import { HandbookListType } from '../../../hooks/useHandbook';
import useIsFirstRender from '../../../hooks/useIsFirstRender';

import EdoSearchForm from './EdoSearchForm';
import EdoSearchResult from './EdoSearchResult';

interface EdoSearchProps {
  handbookList: HandbookListType;
  handbookStatusList: HandbookListType;
  colleaguesList: ColleaguesListType;
  departmentList: ColleaguesListType;
  handBookStageList: HandbookListType;
  currentPage: number;
  setCurrentPage: any;
}
export interface IDocument {
  class: {
    full_name: string;
  };
  document_id: string;
  id: number;
  date: Date;
  contractor_name: null | string;
  status: {
    full_name: string;
  };
  stage: {
    name: string;
  };
  coordination: any[];
}

export interface ISearchResult {
  data: IDocument[];
  meta: {
    total: number;
    per_page: number;
    current_page: number;
  };
}

interface IState {
  searchResult: ISearchResult;
  paramSearch: any;
  isSearching: boolean;
  isShowSearchResult: boolean;
}

enum ActionKind {
  SET_SEARCH_RESULT = 'SET_SEARCH_RESULT',
  TOOGLE_IS_SEARCHING = 'TOOGLE_IS_SEARCHING',
  SHOW_SEARCH_RESULT = 'SHOW_SEARCH_RESULT',
}

type Action =
  | {
      type: 'SET_SEARCH_RESULT';
      payload: {
        searchResult: ISearchResult;
        paramSearch: any;
      };
    }
  | {
      type: 'TOOGLE_IS_SEARCHING';
      payload: boolean;
    }
  | {
      type: 'SHOW_SEARCH_RESULT';
      payload: boolean;
    };

const initialState: IState = {
  searchResult: {
    data: [],
    meta: {
      total: 0,
      per_page: 0,
      current_page: 0,
    },
  },
  paramSearch: null,
  isSearching: false,
  isShowSearchResult: false,
};

const reducer = (state: IState, action: Action) => {
  switch (action.type) {
    case ActionKind.SET_SEARCH_RESULT:
      state.searchResult = action.payload.searchResult;
      state.paramSearch = action.payload.paramSearch;
      break;
    case ActionKind.TOOGLE_IS_SEARCHING:
      state.isSearching = action.payload;
      break;
    case ActionKind.SHOW_SEARCH_RESULT:
      state.isShowSearchResult = action.payload;
      break;
    default:
      throw new Error(`Unknown action type: ${action.type}`);
  }
};

function EdoSearch(props: EdoSearchProps) {
  const {
    handbookList = [],
    handbookStatusList = [],
    colleaguesList = [],
    departmentList = [],
    handBookStageList = [],
    currentPage,
    setCurrentPage,
  } = props;

  const [state, dispatch] = useImmerReducer(reducer, initialState);
  const isFirst = useIsFirstRender();

  const handleSetSearchResult = (
    searchResult: ISearchResult,
    paramSearch: any
  ): void => {
    dispatch({
      type: ActionKind.SET_SEARCH_RESULT,
      payload: { searchResult, paramSearch },
    });
  };

  const handleToggleSearch = (isSearching: boolean): void => {
    dispatch({
      type: ActionKind.TOOGLE_IS_SEARCHING,
      payload: isSearching,
    });
  };

  useEffect(() => {
    if (!isFirst) {
      dispatch({
        type: ActionKind.SHOW_SEARCH_RESULT,
        payload: true,
      });
    }
  }, [dispatch, isFirst, state.searchResult]);

  return (
    <>
      <EdoSearchForm
        handbookList={handbookList}
        handbookStatusList={handbookStatusList}
        handBookStageList={handBookStageList}
        colleaguesList={colleaguesList}
        departmentList={departmentList}
        onSetSearchResult={handleSetSearchResult}
        onToggleSearch={handleToggleSearch}
      />
      {!state.isSearching && state.isShowSearchResult && (
        <EdoSearchResult
          onSetSearchResult={handleSetSearchResult}
          searchResult={state.searchResult}
          paramSearch={state.paramSearch}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
    </>
  );
}

export default EdoSearch;
