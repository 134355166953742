/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Button, Col, Form, Input, notification, Row } from 'antd';
import { DeleteFilled } from '@ant-design/icons';
import axios from 'axios';
import api from './api';
import dayjs from 'dayjs';
import { IRowColumnEditor } from '@modules/Edo/types';
import SearchClientModal from '@modules/common/Client/SearchClientModal';
import { useDispatch } from 'react-redux';
import { addData } from '../../../../store/Edo/actions';

const { Search } = Input;

interface Props {
  fieldID: string;
  fieldName: string;
  title: string;
  required: boolean;
  initialValue?: {
    val: any;
  };
  name?: number;
  constant?: string;
  id?: number;
  onChangeByTd?: any;
  isAdmin?: any;
  isDisabledStatus?: any;
}

interface IUser {
  department: string;
  duty: string;
  full_name: string;
  user_id: number;
}

function FieldUserCard(props: Props) {
  const {
    fieldID,
    fieldName,
    title = 'Title',
    required = false,
    initialValue,
    name,
    constant,
    id,
    onChangeByTd,
    isAdmin,
    isDisabledStatus,
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const form = Form.useFormInstance();
  const [user, setUser] = useState<IUser | null>(null);
  const dispatch = useDispatch();
  const isRowColumnEditor = typeof name === 'number';

  const handleSearchUser = async (iin, event) => {
    if (event?.currentTarget.nodeName === 'INPUT' || iin.length === 0) {
      return;
    }
    try {
      setIsLoading(true);

      const { data } = await api.searchInn({
        iin: iin,
      });

      setUser(data.data);
      if (isAdmin && isDisabledStatus && onChangeByTd) {
        onChangeByTd(fieldID, name);
      }

      console.log('constant', constant);
      

      if (constant == 'cKiasObjAttrTypeIsnForAHD_Subject' || constant == 'cKiasObjAttrType_Subject') {

        const result = await api.searchDocByUserIsn({
          isn: data.data[0].CLIENTISN,
        });

        dispatch(addData(result.data.response));

        if (result.data.success) {
          const { row_column_editor } = form.getFieldsValue();
          let allData = JSON.parse(JSON.stringify(row_column_editor));

          allData = allData.map((column, index) => {

            const getSubject = (data) => {
              for (const key in data) {
                if (data[key].type === 'SUBJECT') {
                  return data[key];
                }
              }
              return null;
            };
            const subject = getSubject(column);

            if (subject && constant == 'cKiasObjAttrType_Subject') {
              const check = Object.keys(column).map((key) => {
                if (column[key]?.type === 'SUBJECT') {
                  return {
                    ...column[key],
                    num_value: iin,
                  };
                } else {
                  return { ...column[key] };
                }
              });
              return check;
            }
            
            if (index == name) {
              const check = Object.keys(column).map((key) => {
                if (column[key]?.full_name == 'Договор АХД') {
                  return {
                    ...column[key],
                    char_value: result.data.response[0].ID,
                    num_value: result.data.response[0].ISN,
                  };
                } else {
                  return { ...column[key] };
                }
              });

              return check;
            } else {
              const data = Object.keys(column).map((key) => {
                if (column[key].type == 'DATE') {
                  return {
                    ...column[key],
                    date_value: dayjs(column[key].date_value).isValid()
                      ? dayjs(column[key].date_value)
                      : null,
                  };
                } else {
                  return { ...column[key] };
                }
              });

              return data;
            }
          });
          const send = allData.map((row) =>
            Object.fromEntries(
              row.map((column) => [
                `id-${column.editor_id}`,
                {
                  display_no: column.display_no,
                  editor_id: column.editor_id,
                  order_no: column.order_no,
                  type: column.type,
                  document_id: id,
                  destination_id: column.destination_id,
                  executor_id: column.executor_id,
                  date_value: column.date_value,
                  num_value: column.num_value,
                  char_value: column.char_value,
                  id: column.id,
                  full_name: column.field_name,
                  department: column.department,
                  duty: column.duty,
                  document_row_id: column.document_row_id,
                  // full_name: column.field_name,
                },
              ])
            )
          );
          form.setFieldsValue({ row_column_editor: send });
          console.log('form', form.getFieldsValue());
        }
      }
    } catch (error) {
      let message;
      let description;

      if (axios.isAxiosError(error)) {
        description =
          typeof error.response?.data.message !== 'undefined'
            ? error.response.data.message
            : error.response?.statusText;

        message = `Ошибка ${error.response?.status}`;
      } else {
        message = String(error);
      }

      notification.error({
        message,
        description,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteUser = () => {
    setUser(null);

    if (isRowColumnEditor) {
      const { row_column_editor } = form.getFieldsValue();

      Object.assign(row_column_editor[name][fieldID], {
        [fieldName]: undefined,
        department: undefined,
        duty: undefined,
        full_name: undefined,
      });

      form.setFieldsValue({ row_column_editor });
      if (isAdmin && isDisabledStatus && onChangeByTd) {
        onChangeByTd(fieldID, name);
      }
    } else {
      form.setFieldsValue({
        [fieldID]: {
          [fieldName]: undefined,
          department: undefined,
          duty: undefined,
          full_name: undefined,
        },
      });
    }
  };

  useEffect(() => {
    if (user) {
      if (isRowColumnEditor) {
        const { row_column_editor } = form.getFieldsValue();

        if (user[0]['JUR_NAME'] && user[0]['JUR_NAME'].length > 0) {
          Object.assign(row_column_editor[name][fieldID], {
            char_value: user[0]['JUR_NAME'],
            full_name: user[0]['JUR_NAME'],
            num_value: user[0]['CLIENTISN'],
            [fieldName]: user[0]['JUR_NAME'],
          });
        } else {
          Object.assign(row_column_editor[name][fieldID], {
            char_value: user[0]['FULL_NAME'],
            full_name: user[0]['FULL_NAME'],
            num_value: user[0]['CLIENTISN'],
            [fieldName]: user[0]['FULL_NAME'],
          });
        }

        form.setFieldsValue({
          row_column_editor,
        });
      } else {
        form.setFieldsValue({
          full_name: user[0]['FULL_NAME'],
          char_value: user[0]['FULL_NAME'],
          num_value: user[0]['CLIENTISN'],
          [fieldID]: user[0]['FULL_NAME'],
        });
      }
    }
  }, [user]);

  return (
    <>
      <Row gutter={15} align="bottom">
        <Col span="24">
          <Form.Item
            label={title}
            rules={[{ required }]}
            initialValue={initialValue?.val?.id}
            name={
              isRowColumnEditor
                ? [name, fieldID, fieldName]
                : [fieldID, fieldName]
            }
          >
            <Input readOnly hidden />
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, curValues) => {
                if (isRowColumnEditor) {
                  return (
                    prevValues.row_column_editor[name][fieldID][fieldName] !==
                    curValues.row_column_editor[name][fieldID][fieldName]
                  );
                } else {
                  return (
                    prevValues[fieldID][fieldName] !==
                    curValues[fieldID][fieldName]
                  );
                }
              }}
            >
              {({ getFieldValue }) => {
                const fieldObject = isRowColumnEditor
                  ? getFieldValue(['row_column_editor', name, fieldID])
                  : getFieldValue(fieldID);
                return (
                  <>
                    {fieldObject?.[fieldName] === null ||
                    fieldObject?.[fieldName] === undefined ? (
                      <Search
                        placeholder="Введите ИИН"
                        loading={isLoading}
                        enterButton
                        allowClear
                        onSearch={handleSearchUser}
                      />
                    ) : (
                      <Input.Group compact>
                        <Form.Item
                          style={{
                            width: 'calc(100% - 46px)',
                            marginBottom: 0,
                          }}
                        >
                          <Input
                            value={
                              fieldObject?.char_value ||
                              initialValue?.val?.char_value
                            }
                            readOnly
                          />
                        </Form.Item>
                        <Button
                          type="primary"
                          icon={<DeleteFilled />}
                          style={{ width: '46px' }}
                          onClick={handleDeleteUser}
                          danger
                          htmlType="button"
                          disabled={isLoading}
                        />
                      </Input.Group>
                    )}
                  </>
                );
              }}
            </Form.Item>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        label="ФИО"
        name={
          isRowColumnEditor
            ? [name, fieldID, 'full_name']
            : [fieldID, 'full_name']
        }
        hidden
      >
        <Input disabled />
      </Form.Item>
    </>
  );
}
export default FieldUserCard;
