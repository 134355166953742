import React, { useMemo, useState } from 'react';
import LibraryContext from '@contexts/LibraryContext';

interface ILibraryProvider {
  children: React.ReactNode;
}

const LibraryProvider: React.FC<ILibraryProvider> = (props) => {
  const [deleteCategoryId, setDeleteCategoryId] = useState<string>('');
  const [editCategoryId, setEditCategoryId] = useState<string>('');
  const [isDocument, setIsDocument] = useState<boolean>(false);
  const [currentFileUuid, setCurrentFileUuid] = useState<string>('');

  const [infoCategoryId, setInfoCategoryId] = useState<string>('');

  const contextValue = useMemo(
    () => ({
      deleteCategoryId,
      editCategoryId,
      infoCategoryId,
      isDocument,
      currentFileUuid,
      setDeleteCategoryId,
      setEditCategoryId,
      setInfoCategoryId,
      setIsDocument,
      setCurrentFileUuid
    }),
    [
      deleteCategoryId,
      editCategoryId,
      infoCategoryId,
      isDocument,
      currentFileUuid,
      setDeleteCategoryId,
      setEditCategoryId,
      setInfoCategoryId,
      setIsDocument,
      setCurrentFileUuid
    ]
  );

  return (
    <LibraryContext.Provider value={contextValue}>
      {props.children}
    </LibraryContext.Provider>
  );
};

export default LibraryProvider;
