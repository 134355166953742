import axios from '@services/api/axios';
import { IClientDataSend } from '../EDS/Clients';

interface ISendSolution {
  document_id: number;
  solution: number;
  remark: string | null;
}

interface ISendSolutionKias {
  userId: number | undefined;
  docIsn: number;
  resolution: string;
  solution: number;
  remark: string | null;
}

interface ISendFileKias {
  fileIsn: string;
  refIsn: string;
  pictType: string;
  fileName: string;
  filePath: string;
}

const api = {
  getData: () => axios.get('/coordination/signature'),
  getDocument: (documentID: string | number) => axios.get(`/document/${documentID}`),
  fileSave: (data: any) => axios.post('/kias/file/save' , data),
  getAgreement: (document_id: number, source = '') => {
    let sourceUrl = '';

    switch (source.toLocaleLowerCase()) {
      case 'kias':
        sourceUrl = '-kias';
        break;
      case 'mycent':
      default:
        sourceUrl = '';
        break;
    }

    return axios.get(`/document-coordination${sourceUrl}/${document_id}`);
  },

  getClientFileUrl: (foreignId: number, isn: number) =>
    axios.post('/document-attachment-file', {
      foreign_id: foreignId,
      isn: isn,
    }),

  sendClientData: (data: IClientDataSend[]) =>
    axios.post('/coordinationAttachmentClient', data),

  deleteDocument: (documentID) => axios.delete(`/document/${documentID}`),
  updateDocumentForm: (data: any) => axios.put(`document/${data.documentId}` , data.data),

  sendSolution: (data: ISendSolution) => axios.post('/coordination/sign', data),
  saveFileToKias: (document_id) => axios.post(`/document/file/saveFileToKias/${document_id}`),
  sendSolutionKias: (data: ISendSolutionKias) =>
    axios.post('/coordination/send/agreement-kias', data),
  getToken: () => axios.get('/getEDS'),
  saveFile: (data) => axios.post('/coordinationSaveAttachment', data),
  checkSignedFile: (data ) => axios.post('https://signature.kz/api/cms/info', data, {
      headers: { 'Content-type': 'multipart/form-data' },
  }),
  findFileByIsn: async (data) => {
    let result;
    await axios
      .post('/coordinationCheckAttachment', data)
      .then((success) => (result = success))
      .catch((error) => {
        if (error.response) {
          result = error.response;
        } else if (error.request) {
          result = error.request;
        } else {
          result = error.message;
        }
      });
    return result;
  },

  getFile: (data: ISendFileKias) =>
    axios.post('/document-attachment-data', data, { responseType: 'blob' }),
  getAgreementFile: (data: any) => axios.post('coordination/sign/download-file' , data ,  { responseType: 'blob' }),
  getDocPrint: (document_isn: any) => axios.post('document/getDocPrintUrl' , document_isn),
  getDataAgreementCS: (class_id: number) => axios.get(`/document/signatory/types/getByClassId/${class_id}`),

  setSignatureTypeCoordByDocId: (data ) => axios.post('/coordination/setSignatureTypeCoordByDocId', data, {
    headers: { 'Content-type': 'multipart/form-data' },
  }),
};

export default api;
