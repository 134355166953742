// redux/reducers.ts
import { ADD_DATA, ADD_CLASS_ID, ADD_IIN_USER } from './Edo/actions';
import { ADD_COMPANY_DATA, ADD_NUMBER_PAGE } from './Company/actions';
import { UPDATE_CONTRACT, ADD_INVOICE_AMOUNT_ISN } from './Quatation/actions'

interface AppState {
  data: string[];
  company_data: number;
  number_page: number;
  contract: string;
  invoice_amount: object[];
  iin_user: string;
}

const initialState: AppState = {
  data: [], // Исходное состояние,
  company_data: 0,
  number_page: 1,
  contract: '',
  invoice_amount: [],
  iin_user: '',
};

export function rootReducer(state = initialState, action: any): AppState {
  switch (action.type) {
    case ADD_DATA:
      return {
        ...state,
        data: action.payload,
      };
    case ADD_CLASS_ID: 
      return {
        ...state,
        data: action.payload,
      }
    case ADD_IIN_USER:
      return {
        ...state,
        iin_user: action.payload,
      }
    case ADD_COMPANY_DATA:
      return {
        ...state,
        company_data: action.payload,
      }
    case ADD_NUMBER_PAGE:
      return {
        ...state,
        number_page: action.payload,
      }
    case UPDATE_CONTRACT:
      return {
        ...state,
        contract: action.payload,
      }
    case ADD_INVOICE_AMOUNT_ISN:
      return {
        ...state,
        contract: action.payload,
      }
    default:
      return state;
  }
}
