import { Button, Col, Form, Input, message, Modal, Row } from 'antd';
import React from 'react';
import { IStateSelectedCategories } from '@modules/OnlineLibrary/interfaces/IStateSelectedCategories';
import axios from 'axios';
import './AddCategoryModal.scss';
import Title from 'antd/es/typography/Title';
import { IRequestData } from '@modules/OnlineLibrary/interfaces/IRequestData';
import useAuth from '@hooks/useAuth';
import api from '@modules/OnlineLibrary/services/api';

interface IPropsAddCategoryModal {
  isModalOpen: boolean;
  onCancel: () => void;
  addSelectedCategory: IStateSelectedCategories;
  getCategories: () => void;
  getAllMainSubCategories: (id: string) => void
};

const AddCategoryModal = ({ isModalOpen, onCancel, addSelectedCategory, getCategories, getAllMainSubCategories }: IPropsAddCategoryModal) => {
  const { selectedCategoryForModal, subCategory } = addSelectedCategory;
  const { user } = useAuth();
  const [addForm] = Form.useForm();

  const onSubmit = async () => {
    const requestData: IRequestData = {
      name: addForm.getFieldValue('folder-name'),
      description: 'test',
      company_id: -1,
      parent_id: selectedCategoryForModal === 'sub' ? subCategory : '982',
      created_by: user?.data?.id,
    };

    try {
      await api.addCategory(requestData);

      if (selectedCategoryForModal === 'root') getCategories();
      if (selectedCategoryForModal === 'sub') getAllMainSubCategories(subCategory);
    } catch (e) {
      message.error('Не получилось добавить папку, попробуйте снова');
    } finally {
      addForm.setFieldValue('folder-name', '');
      onCancel();
    }
  };

  return (
    <Modal
      title={<Title level={4}>Создать папку</Title>}
      open={isModalOpen}
      footer={null}
      onCancel={onCancel}
      className="add-modal-online-library"
      centered
    >
      <Form form={addForm} onFinish={onSubmit} layout="vertical">
        <Row>
          <Col span={18}>
            <Form.Item label={<Title level={5}>Название папки</Title>} name="folder-name">
              <Input placeholder="Введите название папки" size="large"/>
            </Form.Item>
          </Col>
          <Col span={6} className="d-flex align-end justify-end">
            <Form.Item>
              <Button
                htmlType="submit"
                block
                type="primary"
                size="large"
                className="create-button-online-library"
              >
                Создать
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
};

export default AddCategoryModal;
