import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Card,
  Col,
  notification,
  Pagination,
  Row,
  Table,
  Typography,
} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import axios from 'axios';
import EdoSearchModal from './EdoSearchModal';

import { IDocument, ISearchResult } from '.';
import dayjs from 'dayjs';
import api from '../services/api';

const { Title } = Typography;

interface EdoSearchResultProps {
  searchResult: ISearchResult;
  paramSearch: any;
  onSetSearchResult: (searchResult, paramSearch) => void;
  currentPage: number;
  setCurrentPage: any;
}

interface IDocumentColumns extends IDocument {
  key?: React.Key;
}

function EdoSearchResult(props: EdoSearchResultProps) {
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const { searchResult, paramSearch, onSetSearchResult = () => {} } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [modalData, setModalData] = useState();
  const handleOk = () => {
    setIsVisibleModal(false);
  };

  const handleCancel = () => {
    setIsVisibleModal(false);
  };
  const showModal = (data) => {
    setIsVisibleModal(true);
    setModalData(data);
  };
  const columns: ColumnsType<IDocumentColumns> = [
    {
      title: 'Тип документа',
      key: 'class',
      render: (_, record) => (
        <Link to={record.id.toString()}>{record.class?.full_name}</Link>
      ),
    },
    {
      title: '№',
      dataIndex: 'document_id',
      key: 'document_id',
      width: 200,
    },
    {
      title: 'Дата',
      key: 'date',
      width: 150,
      render: (_, record) =>
        dayjs(record.date).isValid()
          ? dayjs(record.date).format('DD.MM.YYYY')
          : '',
    },
    {
      title: 'Контрагент',
      key: 'client',
      render: (_, record) => record?.contractor_name,
    },
    {
      title: 'Статус',
      key: 'status',
      width: 150,
      render: (_, record) => record.stage?.name,
    },
    {
      title: 'Действия',
      width: 150,
      render: (_, record) =>
        record.coordination.length > 0 ? (
          <a onClick={(e) => showModal(record)}>Согласующие</a>
        ) : (
          ''
        ),
    },
  ];

  const paginate = async (pageNumber: number) => {
    try {
      setIsLoading(true);

      const { data } = await api.searchDocuments(pageNumber, paramSearch);
      props.setCurrentPage(pageNumber);

      onSetSearchResult(data, paramSearch);
    } catch (error) {
      let message;

      if (axios.isAxiosError(error)) {
        message = error.message;
      } else {
        message = String(error);
      }

      notification.info({
        message: 'Ошибка',
        description: message,
      });
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (props.currentPage) {
      if (props.currentPage !== searchResult.meta.current_page) {
        paginate(props.currentPage);
      }
    }
  }, [props.currentPage]);

  return (
    <Card data-testid="edo-search-result" className="mt-5">
      <Title level={5} type="secondary" className="mb-5">
        Результаты поиска
      </Title>
      {!!searchResult.data.length ? (
        <>
          <Table
            columns={columns}
            dataSource={searchResult.data}
            scroll={{ y: 400, x: 1200 }}
            bordered
            pagination={false}
            loading={isLoading}
          />
          <EdoSearchModal
            visible={isVisibleModal}
            handleOk={handleOk}
            handleCancel={handleCancel}
            data={modalData}
          />
          <Row className="mt-4" justify="end">
            <Col>
              <Pagination
                current={searchResult.meta.current_page}
                pageSize={searchResult.meta.per_page}
                total={searchResult.meta.total}
                showSizeChanger={false}
                onChange={paginate}
                disabled={isLoading}
              />
            </Col>
          </Row>
        </>
      ) : (
        <p>По вашему запросу ничего не найдено</p>
      )}
    </Card>
  );
}

export default EdoSearchResult;
